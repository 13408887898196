import styles from './HowItWork.module.scss';

const HowItWork = () => (
    <div className={styles.how} id='how_it_work'>
        <div className={styles.how_content}>
            <div className={styles.how_content_img}>
                <img
                    src='/assets/img/descriptor_how_1.png'
                    alt='how it works step 1'
                />
            </div>
            <div className={styles.how_content_card}>
                <div className={styles.how_content_card_title}>
                    Showcase Your Property’s Best Features
                </div>
                <div className={styles.how_content_card_desc}>
                    Our AI-generated descriptions emphasize your Airbnb's
                    standout features, enticing more guests to book and boosting
                    your occupancy rates
                </div>
            </div>
        </div>
        <div className={styles.how_content}>
            <div className={styles.how_content_img}>
                <img
                    src='/assets/img/descriptor_how_2.png'
                    alt='how it works step 2'
                />
            </div>
            <div className={styles.how_content_card}>
                <div className={styles.how_content_card_title}>
                    Stay Seasonally Relevant, Completely Automated
                </div>
                <div className={styles.how_content_card_desc}>
                    Our automated system updates your descriptions to align with
                    seasonal events and holidays, ensuring you capitalize on
                    peak booking times.
                </div>
            </div>
        </div>
        <div className={styles.how_content}>
            <div className={styles.how_content_img}>
                <img
                    src='/assets/img/descriptor_how_3.png'
                    alt='how it works step 3'
                />
            </div>
            <div className={styles.how_content_card}>
                <div className={styles.how_content_card_title}>
                    Tailored Tone for a Consistent Brand Voice
                </div>
                <div className={styles.how_content_card_desc}>
                    Our intelligent algorithm tailors descriptions to your
                    preferred tone, providing a consistent experience for
                    potential guests.
                </div>
            </div>
        </div>
    </div>
);

export default HowItWork;
