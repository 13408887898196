import React, { useState } from 'react';
import DescriptionOnly from '../DescriptionOnly/DescriptionOnly';
import Property from '../../../common/types/Property';
import styles from './PropertyDescription.module.scss';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../app/hooks';
import { getExistingPropertyDescrypt } from '../../../features/descriptor/Descriptor.api';
import {
    faUser,
    faBed,
    faBath,
    faMapMarkerAlt,
    faArrowUpRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectDescriptor } from '../../../features/selectors';
import type { DescriptorState } from '../../../models/StateTypes';
import { LoadingIcon } from '../../../common/components/Loading/Loading';

interface PropertyDescriptionProps {
    property: Property;
}

const PropertyDescription: React.FC<PropertyDescriptionProps> = ({
    property,
}) => {
    const [selectedMonth, setSelectedMonth] = useState('');
    const data: DescriptorState = useSelector(selectDescriptor);
    const dispatch = useAppDispatch();

    const loadSelectedMonthProperty = () => {
        if (!property) return;
        dispatch(getExistingPropertyDescrypt({
            pid: property.pid,
            date: convertMonthYearStringToYYYYMM(selectedMonth),
        })).then((action) => {
            if (action.payload === null && property) {
                setSelectedMonth(convertDateStringToYYYYMM(data.update_time));
            }
        });
    }

    if (data.status === 'loading') {
        return (
            <div className={styles.info_panel}>
                <LoadingIcon style={{marginTop: '250px'}}></LoadingIcon>
            </div>
        );
    }

    const propertyDetail = (
        <div className={styles.property_detail}>
            <div className={styles.property_detail_item}>
                <FontAwesomeIcon icon={faUser} className={styles.icon} />
                <span>{data.max_occupancy}</span>
            </div>
            <div className={styles.property_detail_item}>
                <FontAwesomeIcon icon={faBed} className={styles.icon} />
                <span>{data.beds}</span>
            </div>
            <div className={styles.property_detail_item}>
                <FontAwesomeIcon icon={faBath} className={styles.icon} />
                <span>{data.bathrooms}</span>
            </div>
        </div>
    );

    const viewListing = () => {
        window.open(`https://airbnb.com/rooms/${property.pid}`);
    }

    const MonthDropdown = () => {
        const getMonthYearList = () => {
            const lastDate = new Date(data.update_time);
            const monthList = [];

            for (let i = 0; i < 12; i++) {
                let newDate = lastDate;
                const monthName = newDate.toLocaleString('default', {
                    month: 'long',
                });
                const year = newDate.getFullYear();

                monthList.push(`${monthName} ${year}`);
                newDate = new Date(lastDate.setMonth(lastDate.getMonth() - 1));
            }

            return monthList;
        };

        return (
            <select
                className={styles.date_select}
                onChange={loadSelectedMonthProperty}
                value={selectedMonth}
            >
                {getMonthYearList().map((monthYear, index) => (
                    <option key={index} value={monthYear}>
                        {monthYear}
                    </option>
                ))}
            </select>
        );
    };

    return (
        <div>
            <div className={styles.property_title}>{data.headline}</div>
            <div className={styles.property_wrapper}>
                <div className={styles.description_wrapper}>
                    <div className={styles.description_only_title}>
                        <div className={styles.description_only_title_txt}>
                            Description
                        </div>
                        <div className={styles.date_selector}>
                            <MonthDropdown />
                        </div>
                    </div>

                    <DescriptionOnly data={data} isDemo={false} />
                </div>

                <div className={styles.property_description_wrapper}>
                    <div className={styles.property_description_div}>
                        <div className={styles.property_description_title}>
                            Property
                        </div>
                        <div className={styles.property_description_details}>
                            <img 
                                src={property.photo_url} 
                                alt={property.title} />
                            <div className={styles.property_location}>
                                <FontAwesomeIcon
                                    icon={faMapMarkerAlt}
                                    className={styles.icon}
                                />
                                <span>{data.address}</span>
                            </div>
                            {propertyDetail}
                            <button 
                                className={styles.property_description_btn}
                                onClick={viewListing}>
                                <FontAwesomeIcon icon={faArrowUpRight} className={styles.icon2} />View Listing
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyDescription;

function convertMonthYearStringToYYYYMM(monthYear: string): string {
    const monthNames: { [key: string]: string } = {
        January: '01',
        February: '02',
        March: '03',
        April: '04',
        May: '05',
        June: '06',
        July: '07',
        August: '08',
        September: '09',
        October: '10',
        November: '11',
        December: '12',
    };

    const [month, year] = monthYear.split(' ');

    return `${year}-${monthNames[month]}`;
}

function convertDateStringToYYYYMM(dateString: string): string {
    const date = new Date(dateString);
    return `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, "0")}`;
}

