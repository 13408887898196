// src/components/Topbar.tsx
import React, { useState } from 'react';
import styles from './Topbar.module.scss';
import { faArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from '../../../app/hooks';
import { logoutAsync } from '../../../features/auth/Auth.api';
import { useLocation } from 'react-router-dom';

interface TopbarProps {
    onTabSelect: (tab: 'properties' | 'settings') => void;
}

const Topbar: React.FC<TopbarProps> = ({ onTabSelect }) => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    let selectedInitTab = 'properties';
    if (location.pathname==='/settings') {
        selectedInitTab = 'settings';
    }
    const [selectedTab, setSelectedTab] = useState(
        selectedInitTab,
    );

    const handleTabSelect = (tab: 'properties' | 'settings') => {
        setSelectedTab(tab);
        onTabSelect(tab);
    };

    const handleClickLogout = () => {
        dispatch(logoutAsync())
    }

    return (
        <div className={styles.topbar}>
            <div className={styles.tabar_wrapper}>
                <div className={styles.topbar_content}>
                    <a href='/'>
                        <img
                            src='/assets/icon/hostai.png'
                            alt='hostai logo'
                            className={styles.logo}
                        />
                    </a>
                    <div>
                        <button
                            className={`${styles.tab} ${
                                selectedTab === 'properties'
                                    ? styles.active
                                    : styles.inactive
                            }`}
                            onClick={() => handleTabSelect('properties')}
                        >
                            Properties
                        </button>
                        <button
                            className={`${styles.tab} ${
                                selectedTab === 'settings'
                                    ? styles.active
                                    : styles.inactive
                            }`}
                            onClick={() => handleTabSelect('settings')}
                        >
                            Settings
                        </button>
                    </div>
                    <div className={styles.topbar_logout}>
                        <button onClick={handleClickLogout}>
                            <FontAwesomeIcon
                                icon={faArrowRightFromBracket}
                                className={styles.icon}
                            />
                            <i className={styles.logout_icon} /> Log Out
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Topbar;
