import styles from './Onboarding.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseBuilding } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import Property from '../common/types/Property';



const EachProperty: React.FC<{property: Property}> = ({ property }) => {
    return (
        <div className={styles.onboarding_section_content_properties_item}>
            <div className={styles.onboarding_section_content_properties_item_icon_wrapper}>
                <FontAwesomeIcon icon={faHouseBuilding} />
            </div>
            <div className={styles.onboarding_section_content_properties_item_content}>
                <p>{property.name ?? ''}</p>
                <p>{property.location ?? ''}</p>
            </div>
        </div>
    )    
}

interface YourPropertiesProps {
    properties: Property[];
}


const YourProperties: React.FC<YourPropertiesProps> = ({ properties }) => {
    if (properties.length > 0) {
        return (
            <div className={styles.onboarding_section_content_properties}>
                {properties.map((item, idx) => {
                    return <EachProperty property={item} key={`each_property_${idx}`}></EachProperty>
                })}
            </div>
        )
    } else {
        return <></>
    }
    
}

export default YourProperties;