// PropertyCard.tsx

import React from 'react';
import styles from './PropertyCard.module.scss';
import Property from '../../../common/types/Property';

interface PropertyCardProps {
    property: Property;
    onPropertySelect: (property: Property) => void;
}

const PropertyCard: React.FC<PropertyCardProps> = ({
    property,
    onPropertySelect,
}) => {
    return (
        <div
            className={styles.property_card}
            onClick={() => onPropertySelect(property)}
        >
            <img
                className={styles.property_image}
                src={property.photo_url}
                alt={property.title}
            />
            <div className={styles.property_info}>
                <div className={styles.property_title}>{property.title}</div>
                <div className={styles.property_location}>
                    {property.address}
                </div>
            </div>
        </div>
    );
};

export default PropertyCard;
