import styles from './Generating.module.scss';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';

interface GeneratingProps {
    url: string;
    email: string;
}

const Generating: React.FC<GeneratingProps> = ({ url, email }) => {
    return (
        <div className={styles.generating}>
            <button className={styles.hero_desc_primary_btn} disabled={true}>
                <div className={styles.spinner_icon}>
                    <FontAwesomeIcon icon={faSpinner} />
                </div>
                <span className={styles.button_desc_primarty_btn_txt}>
                    Generating Optimized Description
                </span>
            </button>
            <p className={styles.hero_desc_detail}>
                Analyzing AirBnB listing...
            </p>
            <p className={styles.hero_desc_detail2}>
                Estimated time for generation: approximately 5-10 min.
            </p>
            <div className={styles.email_input_container}>
                <h2 className={styles.email_input_title}>
                    Email submitted: {email}
                </h2>
                <br />
                <p className={styles.email_input_detail}>
                    We'll send your optimized description as soon as it's ready.
                </p>
            </div>
        </div>
    );
};

export default Generating;
