import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/AxiosClient';

export const getPropertyDescrypt = createAsyncThunk(
    'descriptor/getPropertyDescrypt',
    async (targetUrl: string, thunkAPI) => {
        try {
            const response = await axios.post(
                'https://descriptor-property-descriptor-bjzbrkdhea-uc.a.run.app',
                {
                    url: targetUrl,
                    flavor: process.env.REACT_APP_BUILD_ENV,
                    demo: true,
                }
            );
            return response.data;
        } catch (err: any) {
            return null;
        }
    }
);

type GetExistingPropertyDescriptionArgs = {
    pid: string;
    date?: string;
};

export const getExistingPropertyDescrypt = createAsyncThunk(
    'descriptor/getExistingPropertyDescrypt',
    async ({ pid, date }: GetExistingPropertyDescriptionArgs, thunkAPI) => {
        try {
            const response = await axios.post('/get_description', {
                property_id: pid,
                flavor: process.env.REACT_APP_BUILD_ENV,
                date: date,
            });
            return response.data;
        } catch (err: any) {
            return null;
        }
    }
);
