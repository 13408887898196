import { useState } from 'react';
import { sendGA4Event, useAppDispatch } from '../app/hooks';
import { loginAsync } from '../features/auth/Auth.api';
import { Link } from 'react-router-dom';
import styles from './Auth.module.scss';
import { useSelector } from 'react-redux';
import { selectAuthStatus } from '../features/selectors';
import { LogoDialog } from '../common/components/LogoDialog/LogoDialog';

export default () => {

    const [id, setId] = useState('');
    const status = useSelector(selectAuthStatus);

    const dispatch = useAppDispatch();
    const handleClick = () => {
        sendGA4Event('clickSigninSigninPage', { account_id: id, timestamp: Date.now() });
        dispatch(loginAsync({ id }));
    }
    sendGA4Event('viewSigninPage', { timestamp: Date.now() });

    return (
        <div className={styles.login_wrapper}>
            <div className={styles.login_vwrapper}>
                <LogoDialog>
                    <div className={styles.login_title}>Sign into your account</div>
                    <div className={styles.login_form}>
                        <p className={styles.login_form_title}>Email</p>
                        <input onChange={(evt) => setId(evt.target.value)} value={id} className={styles.login_form_input}  placeholder='john@example.com'/>
                        <button onClick={handleClick} className={styles.login_button} disabled={status === 'loading'}>Sign In</button>
                    </div>
                    <div className={styles.divider}></div>
                    <div className={styles.login_signup_wrapper}>
                        <p>Don't have an account yet? <Link to="/signup">Sign Up</Link></p>
                    </div>
                </LogoDialog>
            </div>
        </div>
    )
}