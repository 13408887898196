import { sendGA4Event, useAppDispatch } from '../app/hooks';
import { LogoDialog } from '../common/components/LogoDialog/LogoDialog';
import { loginAsync } from '../features/auth/Auth.api';
import { selectTempEmail } from '../features/selectors';
import styles from './Auth.module.scss';
import { useSelector } from 'react-redux';

export default () => {
    const email = useSelector(selectTempEmail);

    const dispatch = useAppDispatch();


    const handleClickResend = () => {
        sendGA4Event('clickResend', { timestamp: Date.now(), email: email });
        dispatch(loginAsync({ id: email }));
    }
    sendGA4Event('viewCheckEmailPage', { timestamp: Date.now() });

    return (
        <div className={styles.login_wrapper}>
            <div className={styles.login_vwrapper}>
                <LogoDialog style={{gap: '24px'}}>
                    <div className={styles.check_email_desc}>
                        <p className={styles.check_email_desc_title}>Check your email</p>
                        <p className={styles.check_email_desc_content}>We’ve just sent an email with a login link to { email }. This link will expire in 1 hour.</p>
                    </div>
                    <div className={styles.resend}>
                        <p>Not seeing it?</p>
                        <p>Check your spam folder or <a onClick={handleClickResend}>Send another sign-in link</a> </p>
                    </div>
                </LogoDialog>
            </div>
        </div>
    )
}