import { useState } from 'react';
import { useAppDispatch } from '../app/hooks';
import { loginVerifyAsync } from '../features/auth/Auth.api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './Auth.module.scss';
import { AppDispatch } from '../app/store';
import { useDispatch } from 'react-redux';
import { LogoDialog } from '../common/components/LogoDialog/LogoDialog';
import { setMessage } from '../features/common/Common.slice';

let executeIndex = 0;

export default () => {
    const dispatch = useDispatch<AppDispatch>();
    const [searchParams ] = useSearchParams();
    const navigate = useNavigate();

    const apiKey = searchParams.get('apiKey');
    const oobCode = searchParams.get('oobCode');
    const mode = searchParams.get('mode');

    if (executeIndex === 0) {
        if (apiKey && oobCode && mode === 'signIn') {
            dispatch(loginVerifyAsync())
        } else {
            dispatch(setMessage({
                message: 'failed to login, params are empty',
                messageType: 'warning'
            }));
            navigate('/');
        }

        executeIndex += 1;
    }

    
    return (
        <div className={styles.login_wrapper}>
            <div className={styles.login_vwrapper}>
                <LogoDialog>
                    <div className={styles.login_title}>Logging In...</div>
                </LogoDialog>
            </div>
        </div>
    )
}