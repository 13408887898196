import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../app/hooks';

import { getPropertyDescrypt } from '../../../features/descriptor/Descriptor.api';
import DemoDescription from './DemoDescription';
import { selectDescriptor } from '../../../features/selectors';
import type { DescriptorState } from '../../../models/StateTypes';

const Demo = () => {
    const data: DescriptorState = useSelector(selectDescriptor);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!data.submitValue) return;

        const urlPattern = /https:\/\/(?:www\.)?airbnb\.com\/rooms\/(\w+)/;

        if (!urlPattern.test(data.submitValue)) {
            console.log('Invalid URL format.');
            return;
        }
        const match = data.submitValue.match(urlPattern);
        if (!match) {
            console.log('Invalid URL format.');
            return;
        }
        const propertyId = match[1];

        dispatch(
            getPropertyDescrypt(
                // Get existing description,
                // 'https://www.airbnb.com/rooms/655727892930431405'
                data.submitValue
            )
        );
    }, [data.submitValue]);

    if (data.status === 'loading') {
        // add loading bar here, or global loading bar
    }

    return (
        <div>
            {data.submitValue && data.status === 'idle' && (
                <DemoDescription data={data} isDemo={true} />
            )}
        </div>
    );
};

export default Demo;
