// src/descriptor/components/Settings/IntegrationTokenDialog.tsx

import React, { useState } from 'react';
import styles from './IntegrationTokenDialog.module.scss';
import { useDispatch } from 'react-redux';
import { createCredentialsAsync } from '../../../features/auth/Auth.api';
import { Dialog } from '@mui/material';
import { AppDispatch } from '../../../app/store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { updatePMSDetails } from '../../../features/auth/Auth.slice';

export const IntegrationTokenDialog = ({ isOpen, onClose, accountId, pmsName }: { isOpen: boolean, onClose: () => void, accountId: string, pmsName: string }) => {
    const _HOSTAWAY = 'hostaway';
    const [token, setToken] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [clientId, setClientId] = useState('');

    const dispatch = useDispatch<AppDispatch>();

    const handleTokenSubmit = async () => {
        let payload = { accountId: accountId, token: token, pmsName: pmsName, clientId: '' };
        if (pmsName === _HOSTAWAY) {
            payload.clientId = clientId;
        }
        const resultAction = await dispatch(createCredentialsAsync(payload));
        if (createCredentialsAsync.fulfilled.match(resultAction)) {
            onClose(); // Close the dialog on success
            const sync_info = {
                pms_sync_active: true,
                pms_name: pmsName,
            }
            dispatch(updatePMSDetails(sync_info));
        } else {
            // Handle the error case
            setErrorMessage('Token or network error. Please check if the token is valid and not expired.'); // Set the error message
            const sync_info = {
                pms_sync_active: false,
                pms_name: pmsName,
            }
            dispatch(updatePMSDetails(sync_info));
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
            <div className={styles.modal_header}>
                <h2 className={styles.modal_header_title}>Integration</h2>
                <button className={styles.modal_header_button} onClick={onClose}>
                    <FontAwesomeIcon
                        icon={faXmark}
                        size="sm"
                    />
                </button>
            </div>
            <div className={styles.modal_body}>
                {pmsName === _HOSTAWAY && (
                    <div className={styles.form_element}>
                        <label htmlFor="clientId" className={styles.form_label}>
                            Account ID
                        </label>
                        <div className={styles.form_control}>
                            <input
                                id="clientId"
                                type="text"
                                value={clientId}
                                onChange={(e) => setClientId(e.target.value)}
                            />
                        </div>
                    </div>
                )}
                <div className={styles.form_element}>
                    {pmsName === _HOSTAWAY && (
                    <label htmlFor="token" className={styles.form_label}>
                        Client Secret
                    </label> )}
                    {pmsName !== _HOSTAWAY && (<label htmlFor="token" className={styles.form_label}>
                        Integration Token
                    </label>)}

                    <div className={styles.form_control}>
                        <input
                            autoFocus
                            id="token"
                            type="text"
                            value={token}
                            onChange={(e) => setToken(e.target.value)}
                        />
                    </div>
                    {errorMessage &&
                        <p className={styles.form_error}>
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                                size="sm"
                            />
                            {errorMessage}
                        </p>
                    }
                </div>
                <div className={styles.modal_actions}>
                    <button className={`${styles.button_secondary} ${styles.button_sm}`} onClick={onClose}>Cancel</button>
                    <button className={`${styles.button_primary} ${styles.button_sm}`} onClick={handleTokenSubmit}>Save Changes</button>
                </div>
            </div>

        </Dialog>
    );
};