import Topbar from './components/Topbar/Topbar';
import Settings from './components/Settings/Settings';
import React, { useEffect } from 'react';
import { AppRouter } from '../AppRouter';
import { sendGA4Event, useAppSelector } from '../app/hooks';
import { selectSubscription } from '../features/selectors';
import styles from './Descriptions.module.scss';
import { selectAccountId } from '../features/selectors';
import { useNavigate } from 'react-router-dom';

const SettingsWrapper: React.FC = () => {

    const accountId = useAppSelector(selectAccountId);
    const subscription = useAppSelector(selectSubscription);
    useEffect(() => {
      // NOTE: this logic depends on the assumption that any account must have an email once they are logged in
      // If email is non-empty and subscription.status is the this user hasn't signed up yet.
      if (subscription.email && subscription.status === '') {
          AppRouter.navigate('/onboarding')
      }
  }, [subscription.email])

    const navigate = useNavigate();

    const handleTabSelect = (tab: 'properties' | 'settings') => {
        if (tab==='properties') {
            navigate('/descriptions');
        } else {
            navigate('/settings');
        }
    };
    sendGA4Event('viewDescriptionsPage', { timestamp: Date.now() });

    return (
        <div className={styles.descriptions_wrapper}>
            <Topbar onTabSelect={handleTabSelect} />
            <Settings
                account_id={accountId}
                subscription={subscription}
            />
        </div>
    );
};

export default SettingsWrapper;
