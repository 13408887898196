import styles from './SignUpNowSection.module.scss';
import { Link } from 'react-router-dom';
import { sendGA4Event } from '../../../app/hooks';

const SignUpNowSection = () => (
    <div className={styles.info}>
        <div className={styles.info_content}>
            <p className={styles.info_content_title}>
                Elevate Your Airbnb Listing and Boost Your Bookings
            </p>
            <p className={styles.info_content_detail}>
                Sign Up for hostWriter and seize the easiest way to improve your
                Airbnb performance
            </p>
            <Link
                to='/signup'
                onClick={() => {
                    sendGA4Event('clickSignup', { timestamp: Date.now() });
                }}
            >
                <button className={styles.signup_button}>Sign Up Now</button>
            </Link>
        </div>
    </div>
);

export default SignUpNowSection;
