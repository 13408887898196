import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
    changeSubscriptionAsync,
    setSubscriptionTypeAsync,
    fetchAccountInfoAsync,
    loginAsync,
    loginVerifyAsync,
    logoutAsync,
    signupAsync,
} from './Auth.api';
import { setAccessToken } from '../../utils/AxiosClient';
import { AppRouter } from '../../AppRouter';
import { ALL_PROPERTIES } from '../../utils/Constants';
import type { AuthState } from '../../models/StateTypes';

const initialState: AuthState = {
    status: 'idle',
    loggedOn: false,
    uid: '',
    accessToken: '',
    email: '',
    subscription: {
        email: '',
        airbnb_account_id: '',
        main_property: '',
        num_properties: 0,
        type: '',
        plan_interval: '',
        update_time: '',
        status: '',
        pms_sync_active: false,
        pms_name: '',
    },
    properties: [],
    tempLoginEmail: '',
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        refreshLogin: (state, action: PayloadAction<any>) => {
            state.loggedOn = true;
            state.accessToken = action.payload?.accessToken ?? '';
            state.email = action.payload?.email ?? '';
            state.uid = action.payload?.uid ?? '';
            state.status = 'finished';

            setAccessToken(action.payload?.user?.accessToken);
        },
        failLocalLogin: (state) => {
            state.status = 'finished';
        },
        updatePMSDetails: (state, action) => {
            state.subscription.pms_sync_active = action.payload.pms_sync_active;
            state.subscription.pms_name = action.payload.pms_name;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginVerifyAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loginVerifyAsync.fulfilled, (state, action: any) => {
                state.status = 'finished';
                state.loggedOn = true;
                state.accessToken = action.payload?.accessToken ?? '';
                state.email = action.payload?.email ?? '';
                state.uid = action.payload?.uid ?? '';
                setAccessToken(action.payload?.accessToken);
            })
            .addCase(loginVerifyAsync.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(logoutAsync.fulfilled, (state) => {
                state.accessToken = '';
                state.email = '';
                state.uid = '';
                state.status = 'finished';

                AppRouter.navigate('/');
            })
            .addCase(fetchAccountInfoAsync.fulfilled, (state, action) => {
                console.log('fetchAccountInfoAsync.fulfilled');
                console.log(action.payload);
                state.subscription = {
                    email: action.payload?.default_email ?? '',
                    num_properties: action.payload?.all_pids.length ?? 0,
                    main_property:
                        action.payload?.airbnb_ids?.[0]?.main_property ?? '',
                    airbnb_account_id:
                        action.payload?.airbnb_ids?.[0]?.uid ?? '',
                    type: action.payload?.subscription?.type ?? '',
                    plan_interval:
                        action.payload?.subscription?.plan_interval ?? '',
                    update_time:
                        action.payload?.subscription?.update_time ?? '',
                    status: action.payload?.subscription.status ?? '',
                    pms_sync_active: action.payload?.pms?.sync_active ?? false,
                    pms_name: action.payload?.pms?.name ?? '',
                    // HHM: To add pms guesty: action.payload?.subscription.status ?? '',
                };
                console.log('state.subscription:');
                console.log(state.subscription);
                state.properties = action.payload?.all_pids ?? [];

                if (
                    AppRouter.state?.location?.pathname === '/login/callback' ||
                    AppRouter.state?.location?.pathname === '/login' ||
                    AppRouter.state?.location?.pathname === '/signup' ||
                    AppRouter.state?.location?.pathname === '/sentEmail'
                ) {
                    AppRouter.navigate('/descriptions');
                }
            })
            .addCase(changeSubscriptionAsync.fulfilled, (state, action) => {
                console.log('TODO: need to update `plan_interval` here');
                console.log(action);
                console.log(state);
                // TODO: need to update `plan_interval` here
            })
            .addCase(setSubscriptionTypeAsync.fulfilled, (state, action) => {
                state.subscription.type = ALL_PROPERTIES;
            })
            .addMatcher(
                isAnyOf(loginAsync.fulfilled, signupAsync.fulfilled),
                (state, action) => {
                    state.tempLoginEmail = action.meta.arg.id;

                    AppRouter.navigate('/sentEmail');
                }
            );
    },
});

export const { refreshLogin, failLocalLogin, updatePMSDetails } =
    authSlice.actions;

export default authSlice.reducer;
