import React, { useEffect, useState, useRef } from 'react';
import {
    SelectChangeEvent,
} from '@mui/material';

import { loadStripe, Stripe } from '@stripe/stripe-js';
import { isValidAirbnbUrl } from '../utils/airbnbUtils';
import { ALL_PROPERTIES, MOBILE_ALERT, MONTH, ONE_PROPERTY, breakpoints } from '../utils/Constants';

import styles from './Onboarding.module.scss';
import { useSelector } from 'react-redux';
import { sendGA4Event, useAppDispatch } from '../app/hooks';
import { getAirbnbProfileAsync } from '../features/property/Property.api';
import YourProperties from './YourProperties';
import {
    confirmPayAsync,
    updatePlanTypeAsync,
} from '../features/auth/Auth.api';
import {
    selectAccountId,
    selectPropertiesForOnboarding,
    selectProperty,
    selectSubscription,
} from '../features/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { ErrorDialog } from '../common/components/Dialogs/ErrorDialog';
import BillingType from '../common/components/Billing/BillingType';
import TotalPrice from '../common/components/Billing/TotalPrice';
import { AlertDialog } from '../common/components/Dialogs/AlertDialog';
import { Link } from 'react-router-dom';

const OnboardingPage: React.FC = () => {
    // TODO(DK): propertly pass accountId from signin. If no accountId is available, send user to signin page
    // TODO(DK): assume that account has been created with api/create_account
    const [expandedStep, setExpandedStep] = useState(1);
    const [airbnbUrl, setAirbnbUrl] = useState('');
    const [propertiesChoice, setPropertiesChoice] = useState(ALL_PROPERTIES);
    const [billingChoice, setBillingChoice] = useState(MONTH);
    const [readyToPay, setReadyToPay] = useState(false);
    const [stripe, setStripe] = useState<Stripe | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const propertyState = useSelector(selectProperty);

    const properties = useSelector(selectPropertiesForOnboarding);
    const accountId = useSelector(selectAccountId);
    const subscription = useSelector(selectSubscription);

    const windowSize = useRef([window.innerWidth]);

    const alertMessage = MOBILE_ALERT
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

    const openAlertModal = () => {
        setIsAlertModalOpen(true);
    };

    const closeAlertModal = () => {
        setIsAlertModalOpen(false);
    };

    useEffect(() => {
        if (windowSize.current[0] < breakpoints.sm) {
            openAlertModal();
        }
    }, []);

    useEffect(() => {
        if (airbnbUrl === '' && subscription?.main_property) {
            setAirbnbUrl(
                `https://airbnb.com/rooms/${subscription.main_property}`
            );
        }
    }, [subscription]);

    const numListings = properties.length;
    const dispatch = useAppDispatch();

    useEffect(() => {
        const loadStripeLibrary = async () => {
            const stripeInstance = await loadStripe(
                process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? ''
            );
            setStripe(stripeInstance);
        };

        loadStripeLibrary();
    }, []);

    sendGA4Event('viewOnboardingPage', {
        account_id: accountId,
        timestamp: Date.now(),
    });
    const openErrorModal = (errorMessage: string) => {
        setErrorMessage(errorMessage);
        setIsErrorModalOpen(true);
    };

    const closeErrorModal = () => {
        setErrorMessage('');
        setIsErrorModalOpen(false);
    };

    const handleAccordionChange = (step: number) => {
        if (airbnbUrl && isValidAirbnbUrl({ url: airbnbUrl })) {
            setExpandedStep((prev) => (prev === step ? -1 : step));
        }
    };

    const handleBillingChoiceChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setBillingChoice(event.target.value);
    };

    const handleGetAirBnbProperties = () => {
        sendGA4Event('clickAirbnbProfileContinue', {
            account_id: accountId,
            url: airbnbUrl,
            timestamp: Date.now(),
        });
        if (airbnbUrl) {
            if (isValidAirbnbUrl({ url: airbnbUrl })) {
                dispatch(
                    getAirbnbProfileAsync({ airbnbUrl: airbnbUrl, accountId })
                ).then((action) => {
                    if (action.type === 'property/getAirbnbProfile/rejected') {
                        openErrorModal('Network error. Please try again');
                        setReadyToPay(false);
                    } else {
                        handleAccordionChange(2);
                        setReadyToPay(true);
                    }
                });
                return;
            }
        }

        openErrorModal(
            'Please provide a valid Airbnb listing url, e.g. https://airbnb.com/rooms/123456'
        );
    };

    const handleClickConfirmAndPay = async () => {
        sendGA4Event('clickConfirmAndPay', {
            account_id: accountId,
            url: airbnbUrl,
            timestamp: Date.now(),
            plan_interval: billingChoice,
            plan_type: propertiesChoice,
            num_properties: numListings,
        });

        if (!stripe) {
            return openErrorModal(
                'Billing error! Please refresh the browser and try it again'
            );
        }
        if (readyToPay) {
            const confirmPayAction = await dispatch(
                confirmPayAsync({ planInterval: billingChoice, accountId: accountId, numListings: propertiesChoice === ONE_PROPERTY ? 1 : numListings })
            );

            if (confirmPayAction.type === 'auth/confirmPay/fulfilled') {
                const updatePlanAction = await dispatch(
                    updatePlanTypeAsync({
                        planType: propertiesChoice,
                        accountId,
                    })
                );
                if (
                    updatePlanAction.type === 'auth/updateaPlanType/fulfilled'
                ) {
                    if (updatePlanAction.payload === 'ok') {
                        stripe.redirectToCheckout({
                            sessionId: confirmPayAction.payload,
                        });
                        return;
                    }
                }
            }
        }
        openErrorModal('Please provide a valid Airbnb url');
    };

    const handleClickPrevStep = (num: number) => {
        if (properties.length === 0) {
            return;
        }

        if (expandedStep === num) {
            return;
        }

        handleAccordionChange(num);
    };

    return (
        <div className={styles.onboarding_wrapper}>
            <div className={styles.onboarding_vwrapper}>
                <div className={styles.onboarding}>
                    <div className={styles.onboarding_title}>
                        Welcome! Let's set up your account!
                    </div>
                    <div className={styles.onboarding_subtitle}>
                        To finish your account set up, we will connect your
                        account with your Airbnb profile.
                    </div>
                    {/* Section 1 */}
                    <div className={styles.onboarding_section}>
                        <div
                            onClick={() => handleClickPrevStep(1)}
                            style={{
                                cursor:
                                    properties.length > 0 && expandedStep !== 1
                                        ? 'pointer'
                                        : 'default',
                            }}
                            className={`${styles.onboarding_section_number} ${expandedStep === 1
                                    ? styles.onboarding_section_number__active
                                    : ''
                                }`}
                        >
                            1
                        </div>
                        <div className={styles.onboarding_section_content}>
                            <div
                                className={
                                    styles.onboarding_section_content_title
                                }
                            >
                                Import Airbnb Profile
                            </div>
                            <div
                                className={`${styles.onboarding_section_content_hidable
                                    } ${expandedStep === 1
                                        ? ''
                                        : styles.onboarding_section_content_hidable__hidden
                                    }`}
                            >
                                <div
                                    className={
                                        styles.onboarding_section_content_subtitle
                                    }
                                >
                                    Please enter the URL of any of your Airbnb
                                    listing. We’ll use this to import all of
                                    your properties.
                                </div>
                                <div
                                    className={
                                        styles.onboarding_section_content_title
                                    }
                                    style={{ marginTop: '24px' }}
                                >
                                    Airbnb URL
                                </div>
                                <input
                                    onChange={(evt) =>
                                        setAirbnbUrl(evt.target.value)
                                    }
                                    value={airbnbUrl}
                                    className={
                                        styles.onboarding_section_content_input
                                    }
                                    placeholder='https://airbnb.com/rooms/123456'
                                />

                                {propertyState.status !== 'loading' && (
                                    <button
                                        className={
                                            styles.onboarding_section_content_button
                                        }
                                        onClick={handleGetAirBnbProperties}
                                    >
                                        Continue
                                    </button>
                                )}

                                {propertyState.status === 'loading' && (
                                    <button
                                        disabled={true}
                                        className={`${styles.onboarding_section_content_button} ${styles.loading_button}`}
                                        onClick={handleGetAirBnbProperties}
                                    >
                                        <div className={styles.spinner_icon}>
                                            <FontAwesomeIcon icon={faSpinner} />
                                        </div>
                                        <span
                                            className={
                                                styles.button_desc_primarty_btn_txt
                                            }
                                        >
                                            Continue
                                        </span>
                                    </button>
                                )}
                            </div>
                        </div>
                        <div
                            className={styles.onboarding_section_vdivier}
                        ></div>
                    </div>
                    {/* Section 2 */}
                    <div className={styles.onboarding_section}>
                        <div
                            onClick={() => handleClickPrevStep(2)}
                            style={{
                                cursor:
                                    properties.length > 0 && expandedStep !== 2
                                        ? 'pointer'
                                        : 'default',
                            }}
                            className={`${styles.onboarding_section_number} ${expandedStep === 2
                                    ? styles.onboarding_section_number__active
                                    : ''
                                }`}
                        >
                            2
                        </div>
                        <div className={styles.onboarding_section_content}>
                            <div
                                className={
                                    styles.onboarding_section_content_title
                                }
                            >
                                Confirm properties
                            </div>
                            <div
                                className={`${styles.onboarding_section_content_hidable
                                    } ${expandedStep === 2
                                        ? ''
                                        : styles.onboarding_section_content_hidable__hidden
                                    }`}
                            >
                                <div
                                    className={
                                        styles.onboarding_section_content_title_gray
                                    }
                                    style={{ marginTop: '24px' }}
                                >
                                    Your properties
                                    <span
                                        className={
                                            styles.onboarding_section_content_total
                                        }
                                    >
                                        {numListings} total
                                    </span>
                                </div>
                                <YourProperties
                                    properties={properties}
                                ></YourProperties>

                                <button
                                    className={
                                        styles.onboarding_section_content_button
                                    }
                                    onClick={() => handleAccordionChange(3)}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                        <div
                            className={styles.onboarding_section_vdivier}
                        ></div>
                    </div>
                    {/* Section 3 */}
                    <div className={styles.onboarding_section}>
                        <div
                            className={`${styles.onboarding_section_number} ${expandedStep === 3
                                    ? styles.onboarding_section_number__active
                                    : ''
                                }`}
                        >
                            3
                        </div>
                        <div className={styles.onboarding_section_content}>
                            <div
                                className={
                                    styles.onboarding_section_content_title
                                }
                            >
                                Checkout
                            </div>
                            <div
                                className={`${styles.onboarding_section_content_hidable
                                    } ${expandedStep === 3
                                        ? ''
                                        : styles.onboarding_section_content_hidable__hidden
                                    }`}
                            >
                                <div
                                    className={
                                        styles.onboarding_section_content_subtitle
                                    }
                                >
                                    You can test hostWriter with one of
                                    your properties for free of charge <Link to="/hostwriter" target="_blank" rel="noopener noreferrer">here</Link>
                                </div>

                                <div
                                    className={
                                        styles.onboarding_section_content_title
                                    }
                                    style={{ marginTop: '24px' }}
                                >
                                    Billing
                                </div>

                                <BillingType
                                    plan={billingChoice}
                                    onChangeAction={handleBillingChoiceChange}
                                />

                                <div
                                    className={
                                        styles.onboarding_section_content_title
                                    }
                                    style={{ marginTop: '24px' }}
                                >
                                    Total
                                </div>

                                <TotalPrice
                                    propertyType={propertiesChoice}
                                    plan={billingChoice}
                                    numOfListing={numListings} />

                                <button
                                    className={
                                        styles.onboarding_section_content_button
                                    }
                                    onClick={handleClickConfirmAndPay}
                                >
                                    Confirm & Pay
                                </button>
                            </div>
                        </div>
                    </div>
                    <ErrorDialog
                        isErrorModalOpen={isErrorModalOpen}
                        onClose={closeErrorModal}
                        errorMessage={errorMessage}
                    />
                    <AlertDialog
                        isAlertModalOpen={isAlertModalOpen}
                        onClose={closeAlertModal}
                        alertMessage={alertMessage}
                    />
                </div>
            </div>
        </div>
    );
};

export default OnboardingPage;
