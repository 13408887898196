import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { CommonState, MessageType } from '../../models/StateTypes';

const initialState: CommonState = {
    message: '',
    messageType: 'info',
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setMessage: (state, action: PayloadAction<MessageType>) => {
            state.message = action.payload.message;
            state.messageType = action.payload.messageType ?? 'info';
        },
        clearMessage: (state) => {
            state.message = '';
        },
    },
});

export const { setMessage, clearMessage } = commonSlice.actions;

export default commonSlice.reducer;
