// Descriptor landing page: https://gethostai.com/hostwriter

import { Component } from 'react';
import Topbar from '../common/components/Topbar/Topbar';
import Hero from './components/Hero/Hero';
import Footer from '../common/components/Footer/Footer';
import Demo from './components/Demo/Demo';
import InfoSection from './components/InfoSection/InfoSection';
import HowItWork from './components/HowItWork/HowItWork';
import { sendGA4Event } from '../app/hooks';
import SignUpNowSection from './components/SignUpNowSection/SignUpNowSection';

class Descriptor extends Component {
    componentDidMount(): void {
        if (window?.location?.hash && window?.location?.hash !== '#') {
            let section = document.querySelector(window.location.hash);
            section?.scrollIntoView?.();

            setTimeout(() => {
                section = document.querySelector(window.location.hash);
                section?.scrollIntoView?.();
            }, 100);
        }
        sendGA4Event('viewDescriptorPage', { timestamp: Date.now() });
    }

    render() {
        return (
            <div>
                <Topbar hostWriter={true} />
                <Hero />
                <Demo />
                <InfoSection />
                <HowItWork />
                <SignUpNowSection />
                <Footer />
            </div>
        );
    }
}

export default Descriptor;
