export const isValidAirbnbUrl = ({ url }: { url: string }) => {
    try {
        new URL(url);
    } catch (error) {
        return false;
    }
    if (!url.toLowerCase().includes('airbnb')) {
        return false;
    }
    const listingId = parseListingId({ urlString: url });
    if (listingId) {
        const regex = /^\d+$/;
        return regex.test(listingId);
    } else {
        return false;
    }
};

export const parseListingId = ({ urlString }: { urlString: string }) => {
    const url = new URL(urlString);
    return url.pathname.split('/').pop();
};
