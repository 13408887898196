import { Action, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { createAccountInfoAsync, fetchAccountInfoAsync } from '../features/auth/Auth.api';
import { getStore } from './store';
import { clearMessage, setMessage } from '../features/common/Common.slice';
import { updateEmailAsync } from '../features/auth/Auth.api';
import { AppRouter } from '../AppRouter';

export const actionChainMiddleware: Middleware = (api: MiddlewareAPI) => (
    next: Dispatch
) => <A extends Action>(action: any) => {
    setTimeout(() => chainedActionHandling(action), 0);
    return next(action);
};

let timer: any = null;

const chainedActionHandling = (action: any) => {

    const store = getStore();

    if (action.type === 'auth/refreshLogin' || action.type === 'auth/loginVerifyAsync/fulfilled') {
        store.dispatch(fetchAccountInfoAsync(action.payload.uid));
        // store.dispatch(fetchAccountInfoAsync('Bh6iHE3zlelMFXrV8WTX'));

    }

    if (action.type === 'auth/fetchAccountInfo/fulfilled') {
        const { email, uid } = store.getState().auth;
        if (action.payload === null) {
            store.dispatch(createAccountInfoAsync({account_id: uid, email: email}));
        } else if (!action.payload.default_email) {
            store.dispatch(updateEmailAsync({ email, account_id: uid }));
        }
    }

    if (action.type === 'auth/createAccountInfo/fulfilled') {
        if (action.payload === 'ok') {
            store.dispatch(fetchAccountInfoAsync(action.meta.arg.account_id));
        }
    }



    if (action.type === 'auth/updateEmail/rejected' || 
        action.type === 'auth/changeSubscriptionAsync/rejected' || 
        action.type === 'auth/setSubscriptionType/rejected' || 
        action.type === 'auth/cancelSubscription/rejected') {

        store.dispatch(setMessage({
            message: 'Something went wrong. Please try again later.',
            messageType: 'error',
        }));
    }

    if (action.type === 'auth/setSubscriptionType/fulfilled') {
        store.dispatch(setMessage({
            message: 'All property plan is updated',
        }));
    }

    if (action.type === 'auth/cancelSubscription/fulfilled') {
        store.dispatch(setMessage({
            message: 'Subscription cancelled successfully',
        }));
    }

    if (action.type === 'auth/updateEmail/fulfilled') {
        store.dispatch(setMessage({
            message: 'Email updated successfully',
        }));
    }

    if (action.type === 'auth/loginAsync/rejected' || action.type === 'auth/loginVerifyAsync/rejected' ) {
        store.dispatch(setMessage({
            message: 'failed to loggin ' + (action.error?.message ?? ''),
            messageType: 'error',
        }));
    }

    if (action.type === 'auth/loginAsync/fulfilled') {
        store.dispatch(setMessage({
            message: 'Please check the email',
        }));
    }
    
    if (action.type === 'auth/loginVerify/fulfilled') {
        store.dispatch(setMessage({
            message: 'logged on succesfully',
        }));
    }

    if (action.type === 'auth/loginVerify/rejected') {
        store.dispatch(setMessage({
            message: 'failed to login',
            messageType: 'warning'
        }));

        AppRouter.navigate('/login')
    }

    if (action.type === 'auth/changeSubscriptionAsync/fulfilled') {
        store.dispatch(setMessage({
            message: 'logged on succesfully',
        }))
    }

    if (action.type === 'descriptor/getExistingPropertyDescrypt/fulfilled') {
        if (action.payload === null) {
            store.dispatch(setMessage({
                message: 'There is no history for the selected month.',
                messageType: 'error',
            }))
        }
    }

    if (action.type === 'common/setMessage') {
        if (timer !== null) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            store.dispatch(clearMessage());
        }, 5000)

    }
}