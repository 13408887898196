import styles from './Hero.module.scss';
import UrlInput from './UrlInput';
import React, { useState } from 'react';
import { useInputValue } from './InputContext';
import { useAppDispatch } from '../../../app/hooks';
import { setDescriptorSubmitValue } from '../../../features/descriptor/Descriptor.slice';
import Generating from '../Generating/Generating';
import { useSelector } from 'react-redux';
import { selectDescriptor } from '../../../features/selectors';
import type { DescriptorState } from '../../../models/StateTypes';

const Hero = () => {
    const data: DescriptorState = useSelector(selectDescriptor);
    const [urlValue, setUrlValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const dispatch = useAppDispatch();

    const handleSubmit = (email: string, url: string) => {
        setEmailValue(email);
        setUrlValue(url);
        dispatch(setDescriptorSubmitValue(url));
    };

    const inputInit = (
        <>
            <div className={styles.hero_desc_buttons}>
                <UrlInput handleSubmit={handleSubmit} />
            </div>
            <p className={styles.hero_desc_detail}>
                Estimated time: 5-10 minutes. We will email you the optimized description once it is generated.
            </p>
        </>
    );
    const inputLoaded = (
        <>TODO: Subscribe button or email input to send this to their email</>
    );
    return (
        <div className={styles.hero_wrapper}>
            <div className={styles.hero}>
                <div className={styles.hero_desc}>
                    <p className={styles.hero_desc_title}>
                        Increase Your Visibility on Airbnb with
                        <span> Conversion Optimized </span> Descriptions
                    </p>
                    <p className={styles.hero_desc_detail}>
                        Enter your email and listing's Airbnb URL and watch the magic
                        unfold
                    </p>
                    {!data.submitValue && data.status === 'idle' && inputInit}
                    {data.submitValue && data.status === 'loading' && (
                        <Generating url={urlValue} email={emailValue} />
                    )}
                    {/* {data.submitValue && data.status === 'idle' && inputLoaded} */}
                </div>
            </div>
        </div>
    );
};

export default Hero;
