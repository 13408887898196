
import { initializeApp } from 'firebase/app';
import { browserLocalPersistence, getAuth, setPersistence } from 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? '',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? '',
    appId: process.env.REACT_APP_FIREBASE_APP_ID ?? '',
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);

export { auth };



