import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AggregateData } from '../../models/AggregateData';
import { getAggregateDataAsync } from './Dashboard.api';
import { DashboardState } from '../../models/StateTypes';

const initialState: DashboardState = {
    status: 'idle',
    adSpend: 0,
    users: 0,
    revenue: 0,
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setAggregate: (state, action: PayloadAction<AggregateData>) => {
            state.adSpend = action.payload.adSpend;
            state.users = action.payload.users;
            state.revenue = action.payload.revenue;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAggregateDataAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAggregateDataAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                dashboardSlice.caseReducers.setAggregate(state, action);
            })
            .addCase(getAggregateDataAsync.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const { setAggregate } = dashboardSlice.actions;

export default dashboardSlice.reducer;
