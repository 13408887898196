// src/descriptor/components/Settings/IntegrationToggle.tsx

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateSyncWithPmsAsync } from '../../../features/auth/Auth.api';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AppDispatch } from '../../../app/store';
import { updatePMSDetails } from '../../../features/auth/Auth.slice';

interface IntegrationToggleProps {
    initialChecked: boolean;
    onToggle: (enabled: boolean) => void; // Define the onToggle prop
    accountId: string;
    pmsName: string;
}

export const IntegrationToggle = ({ initialChecked, onToggle, accountId, pmsName }: IntegrationToggleProps) => {
    const [checked, setChecked] = useState(initialChecked);

    console.log('IntegrationToggle: useState(initialChecked)=', useState(initialChecked));
    console.log('IntegrationToggle: accountId=', accountId);
    console.log('IntegrationToggle: initialChecked=', initialChecked);
    console.log('IntegrationToggle: checked=', checked);
    const dispatch = useDispatch<AppDispatch>();

    const handleToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newChecked = event.target.checked;
        setChecked(newChecked);
        const resultAction = await dispatch(updateSyncWithPmsAsync(
            { accountId:accountId, syncWithPms: newChecked, pmsName: pmsName }));
        if (updateSyncWithPmsAsync.fulfilled.match(resultAction)) {
            // If the toggle is turned on, invoke the onToggle callback
            onToggle(newChecked);
            const sync_info = {
                pms_sync_active: newChecked,
                pms_name: pmsName,
            }
            dispatch(updatePMSDetails(sync_info));
        } else {
            // Handle the error case
            setChecked(!newChecked); // Revert the toggle state
        }
    };

    return (
        <FormControlLabel
            control={<Switch checked={initialChecked} onChange={handleToggle} />}
            label={"Sync with " + pmsName[0].toUpperCase() + pmsName.slice(1)}
        />
    );
};