import styles from './InfoSection.module.scss';

const InfoSection = () => (
    <div className={styles.info}>
        <div className={styles.info_content}>
            <p className={styles.info_content_title}>
                Get Automated Descriptions Delivered to Your Inbox or PMS
            </p>
            <p className={styles.info_content_detail}>
                Subscribe for monthly updates tailored to your property's unique
                charm
            </p>
        </div>
    </div>
);

export default InfoSection;
