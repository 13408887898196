import React, { FC, useState, ReactNode } from 'react';
import styles from './DescriptionSection.module.scss';

interface DescriptionSectionProps {
    title: string;
    description: string;
    oldDescription?: string;
    whyGoods?: ReactNode[];
    isDemo: boolean;
}

const DescriptionSection: FC<DescriptionSectionProps> = ({
    title,
    description,
    oldDescription,
    whyGoods,
    isDemo,
}) => {
    const [selected, setSelected] = useState<'optimized' | 'before'>(
        'optimized'
    );

    const buttons = (
        <div className={styles.button_container}>
            <button
                className={`${styles.button} ${
                    selected === 'optimized' ? styles.selected : ''
                }`}
                onClick={() => setSelected('optimized')}
            >
                Optimized
            </button>
            <button
                className={`${styles.button} ${
                    selected === 'before' ? styles.selected : ''
                }`}
                onClick={() => setSelected('before')}
            >
                Before
            </button>
        </div>
    );
    const why_goods = (
        <div className={`${styles.why_goods}`}>
            <ul className={styles.why_goods_list}>{whyGoods}</ul>
        </div>
    );

    const renderDescription = () => {
        if (selected === 'optimized') {
            return (
                <>
                    <div
                        className={styles.description_text}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                    {isDemo && whyGoods && why_goods}
                </>
            );
        }

        return (
            <div
                className={styles.description_text}
                dangerouslySetInnerHTML={{
                    __html: oldDescription || 'This section was missing.',
                }}
            />
        );
    };

    return (
        <div className={styles.description_wrapper}>
            <div className={styles.description_title}>{title}</div>
            <div className={styles.description_value}>
                {(oldDescription || isDemo) && (
                    <div className={styles.description_value_button_wrapper}>
                        {buttons}
                    </div>
                )}
                <div className={styles.description_value_content_wrapper}>
                    {renderDescription()}
                </div>
            </div>
        </div>
    );
};

export default DescriptionSection;
