import React, { FC, useEffect } from 'react';
import styles from './Descriptions.module.scss';
import { LoadingIcon } from '../common/components/Loading/Loading';
import { fetchAccountInfoAsync } from '../features/auth/Auth.api';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectAccountId } from '../features/selectors';
import { PAYMENT_CALLBACK_INTERVAL } from '../utils/Constants';

type PaymentCallbackProps = {
    onSuccess: () => void;
};

const PaymentCallback: FC<PaymentCallbackProps> = ({ onSuccess }) => {
    const dispatch = useAppDispatch();
    const accountId = useAppSelector(selectAccountId);

    useEffect(() => {
        let interval: NodeJS.Timer;
        if (accountId) {
            interval = setInterval(() => {
                dispatch(fetchAccountInfoAsync(accountId)).then((res) => {
                    if (!!res.payload.subscription.status) {
                        onSuccess();
                    }
                });
            }, PAYMENT_CALLBACK_INTERVAL);

            return () => {
                clearInterval(interval);
            };
        }
    }, [accountId]);

    return (
        <div className={styles.callback}>
            <div className={styles.wrapper}>
                <LoadingIcon className={styles.loading} />
                <div className={styles.message}>
                    We're processing your payment and updating your
                    subscription. Please wait...
                </div>
            </div>
        </div>
    );
};

export default PaymentCallback;
