import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';

import { useEffect } from 'react';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const useClarity = (clarityId: string) => {
    useEffect(() => {
        const initializeClarity = () => {
            window.clarity =
                window.clarity ||
                function () {
                    (window.clarity.q = window.clarity.q || []).push(arguments);
                };

            const scriptElement = document.createElement('script');
            scriptElement.async = true;
            scriptElement.src = `https://www.clarity.ms/tag/${clarityId}`;
            document.head.appendChild(scriptElement);
        };

        initializeClarity();
    }, [clarityId]);
};

export default useClarity;

const useGA4 = (ga4Id: string) => {
    useEffect(() => {
        const initializeGA4 = () => {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function gtag() {
                window.dataLayer.push(arguments);
            };

            window.gtag('js', new Date());
            window.gtag('config', ga4Id);

            const scriptElement = document.createElement('script');
            scriptElement.async = true;
            scriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${ga4Id}`;
            document.head.appendChild(scriptElement);
        };

        initializeGA4();
    }, [ga4Id]);
};

const sendGA4Event = (eventName: string, eventParams?: object) => {
    if (typeof window.gtag === 'function') {
        window.gtag('event', eventName, eventParams);
    }
};

export { useGA4, sendGA4Event };
