import { useState } from 'react';
import { sendGA4Event, useAppDispatch } from '../app/hooks';
import { signupAsync } from '../features/auth/Auth.api';
import { setMessage } from '../features/common/Common.slice';
import styles from './Auth.module.scss';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectAuthStatus } from '../features/selectors';
import { LogoDialog } from '../common/components/LogoDialog/LogoDialog';

export default () => {

    const [id, setId] = useState('');

    const status = useSelector(selectAuthStatus);

    const dispatch = useAppDispatch();
    const handleClick = () => {
        sendGA4Event('clickSignupSignupPage', { account_id: id, timestamp: Date.now() });
        dispatch(signupAsync({ id }));
    }
    sendGA4Event('viewSignupPage', { timestamp: Date.now() });

    return (
        <div className={styles.login_wrapper}>
            <div className={styles.login_vwrapper}>
                <LogoDialog>
                    <div className={styles.login_title}>Create your account</div>
                    <div className={styles.login_form}>
                        <p className={styles.login_form_title}>Email</p>
                        <input onChange={(evt) => setId(evt.target.value)} value={id} className={styles.login_form_input} placeholder='john@example.com'/>
                        <button onClick={handleClick} className={styles.login_button} disabled={status === 'loading'}>Continue</button>
                    </div>
                    <div className={styles.login_signup_wrapper}>
                        <p style={{ marginBottom: '0' }}>Already have an account? <Link to="/login">Sign in</Link></p>
                    </div>
                    <div className={styles.divider}></div>
                    <div className={styles.tos}>

                            By signing up, you agree to our
                            <Link to="https://share-docs.clickup.com/25578335/d/h/rcjuz-2227/3513355be586204">
                                Terms of Service
                            </Link> and 
                            <Link to="https://share-docs.clickup.com/25578335/d/h/rcjuz-2207/78219b00d5d2e71">
                                Privacy Policy
                            </Link>

                    </div>
                </LogoDialog>
            </div>
        </div>
    )
}