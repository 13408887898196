import React, { useState } from 'react';
import styles from './Properties.module.scss';
import PropertyIndex from './PropertyIndex';
import PropertyDescription from './PropertyDescription';
import Property from '../../../common/types/Property';
import { LogoDialog } from '../../../common/components/LogoDialog/LogoDialog';
import { DescriptorState } from '../../../models/StateTypes';
import { useSelector } from 'react-redux';
import { selectDescriptor } from '../../../features/selectors';
import { useAppDispatch } from '../../../app/hooks';
import { getExistingPropertyDescrypt } from '../../../features/descriptor/Descriptor.api';

interface PropertiesProps {
    properties: Property[];
}

const Properties: React.FC<PropertiesProps> = ({ properties }) => {
    const disaptch = useAppDispatch();

    const [selectedProperty, setSelectedProperty] = useState<Property | null>(
        null
    );
    const data: DescriptorState = useSelector(selectDescriptor);

    const handlePropertySelect = (property: Property) => {
        setSelectedProperty(property);
        disaptch(
            getExistingPropertyDescrypt({
                pid: property.pid,
            })
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.property_index}>
                <PropertyIndex
                    properties={properties}
                    onPropertySelect={handlePropertySelect}
                    isSetting={false}
                />
            </div>
            <div className={styles.property_description}>
                {selectedProperty && data.status !== 'notReady' && (
                    <PropertyDescription property={selectedProperty} />
                )}

                {selectedProperty && data.status === 'notReady' && (
                    <LogoDialog
                        style={{
                            gap: '24px',
                            marginTop: '200px',
                            marginLeft: '204px',
                        }}
                    >
                        <div className={styles.info_panel_message}>
                            Good things take time! ⏳ We're crafting your
                            description with care, and it'll be ready in a few
                            hours. You'll get a happy email notification from us
                            then 🎉📩.
                        </div>
                    </LogoDialog>
                )}

                {properties.length === 0 && (
                    <LogoDialog
                        style={{
                            gap: '24px',
                            marginTop: '200px',
                            marginLeft: '204px',
                        }}
                    >
                        <div className={styles.info_panel_message}>
                            We're collecting your information... It'll take a
                            day. Please check later
                        </div>
                    </LogoDialog>
                )}
                {properties.length > 0 && !selectedProperty && (
                    <LogoDialog
                        style={{
                            gap: '24px',
                            marginTop: '200px',
                            marginLeft: '204px',
                        }}
                    >
                        <div className={styles.info_panel_message}>
                            Please select a property on the left.
                        </div>
                    </LogoDialog>
                )}
            </div>
        </div>
    );
};

export default Properties;
