import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export const ErrorDialog = ({ isErrorModalOpen, onClose, errorMessage }: {isErrorModalOpen: boolean, onClose: () => void, errorMessage: string }) => {
    return (
        <Dialog open={isErrorModalOpen} onClose={onClose}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )

};