import { ONE_PROPERTY } from "./Constants";
import { MONTH } from "./Constants";
import { ALL_PROPERTIES } from "./Constants";

export const waitTime = async (ms: number = 100) => {
    return new Promise<void>((res) => {
        setTimeout(() => {
            res();
        }, ms);
    });
};

const getPriceString = (
    {plan, price}
    : {plan: string, price: number}) => {
    if (plan === MONTH) {
        return `$${price.toFixed(2)}/month`;
    } else {
        return `$${price.toFixed(2)}/year`;
    }
}

export const getFullUnitPrice = (plan: string = MONTH) => {
    const pricePerListing = plan === MONTH ? 3 : 24;

    return getPriceString({plan: plan, price: pricePerListing})
};

export const getFullPrice = ({
    type = ALL_PROPERTIES,
    plan = MONTH,
    numOfListing = 1,
}: { type?: string; plan?: string, numOfListing?: number } = {}) => {
    const pricePerListing = plan === MONTH ? 3 : 24;
    const listings = type === ONE_PROPERTY ? 1 : numOfListing;
    const totalPrice = pricePerListing * listings;

    return getPriceString({plan: plan, price: totalPrice})
};

export const getDiscountedPrice = ({
    type = ALL_PROPERTIES,
    plan = MONTH,
    numOfListing = 1,
}: { type?: string; plan?: string, numOfListing?: number } = {}) => {
    const pricePerListing = plan === MONTH ? 1.5 : 12;
    const listings = type === ONE_PROPERTY ? 1 : numOfListing;
    const totalPrice = pricePerListing * listings;
    
    return getPriceString({plan: plan, price: totalPrice})
};

export const getUnitPrice = (plan: string) => {
    const pricePerListing = plan === MONTH ? 1.5 : 12;

    return getPriceString({plan: plan, price: pricePerListing})
};