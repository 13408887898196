import styles from './UrlInput.module.scss';
import { faSparkles } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { sendGA4Event } from '../../../app/hooks';
import AxiosClient from '../../../utils/AxiosClient';

interface UrlInputProps {
    handleSubmit: (email: string, url: string) => void;
}

const UrlInput: React.FC<UrlInputProps> = ({ handleSubmit }) => {
    const [emailValue, setEmailValue] = useState('');
    const [urlValue, setUrlValue] = useState('');

    const [showErrorMessage, setShowErrorMessage] = useState('');
    const pattern = /^https:\/\/(?:www\.)?airbnb\.com\/rooms\/(\w+)(\?[^\s]*)?$/;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/;

    const emailHandleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setEmailValue(event.target.value);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setUrlValue(event.target.value);
    };

    const isValidURL = (url: string): boolean => {
        return pattern.test(url);
    };

    const isValidEmail = (email: string): boolean => {
        return emailPattern.test(email);
    }

    const handleButtonClick = (): void => {
        sendGA4Event('clickOptimize', { timestamp: Date.now(), url: urlValue, email: emailValue });

        try {
            if (!isValidEmail(emailValue)) {
                setShowErrorMessage('Please enter a valid email');
            } else if (!isValidURL(urlValue)) {
                setShowErrorMessage('Please enter a valid URL starting with https://airbnb.com/rooms/');
            } else {
                const match = urlValue.match(pattern);
                if (match) {
                    setShowErrorMessage('');
                    const propertyId = match[1];
                    AxiosClient.post(
                        'https://descriptor-handle-demo-email-submission-bjzbrkdhea-uc.a.run.app',
                        {
                            property_id: propertyId,
                            flavor: process.env.REACT_APP_BUILD_ENV,
                            demo_email_recipient: emailValue,
                        }
                    ).then((response) => {
                        console.log(response.data);
                    });

                    handleSubmit(emailValue, urlValue);
                } else {
                    setShowErrorMessage('Please enter a valid URL starting with https://airbnb.com/rooms/');
                    console.log(`URL does not match the pattern: ${urlValue}`);
                }
            }
        } catch (err: any) {
            console.error('Error in descriptor-handle-demo-email-submission:', err);
        }
    };

    return (
        <div className={styles.url_input_wrapper}>
            <input
                type='email'
                className={styles.hero_desc_input}
                placeholder='Your email'
                value={emailValue}
                onChange={emailHandleChange}
                pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
            />
            <input
                type='text'
                id='textInput'
                value={urlValue}
                onChange={handleChange}
                placeholder='Enter URL of your Airbnb listing...'
                className={styles.hero_desc_input}
                // pattern='https:\/\/(?:www\.)?airbnb\.com\/rooms\/(\w+)(\?[^\s]*)?'
                // title="Please enter a valid URL, starting with 'https://airbnb.com/rooms/'"
            />
            <button className={styles.hero_desc_primary_btn} onClick={handleButtonClick}>
                <div className={styles.sparkle_icon}>
                    <FontAwesomeIcon icon={faSparkles} />
                </div>
                Optimize
            </button>
            {showErrorMessage && (
                <div className={styles.error_message}>
                    {showErrorMessage}
                </div>
            )}
        </div>
    );
};

export default UrlInput;
