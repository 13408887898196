import { Link } from 'react-router-dom';
import styles from './Topbar.module.scss';
import { sendGA4Event, useAppSelector } from '../../../app/hooks';
import { selectIsLoggedOn } from '../../../features/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const Topbar = ({ hostWriter = false }) => {
    const loggedOn = useAppSelector(selectIsLoggedOn);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const loggedOutLinks = (
        <>
            <Link
                to='/login'
                onClick={() => {
                    sendGA4Event('clickSignin', { timestamp: Date.now() });
                }}
            >
                <button className={styles.topbar_second_button}>Sign in</button>
            </Link>
            <Link
                to='/signup'
                onClick={() => {
                    sendGA4Event('clickSignup', { timestamp: Date.now() });
                }}
            >
                <button className={styles.topbar_button}>Sign Up</button>
            </Link>
        </>
    );

    const loggedOnLinks = (
        <Link
            to='/descriptions'
            onClick={() => {
                sendGA4Event('clickMyDescriptions', {
                    timestamp: Date.now(),
                });
            }}
        >
            <button className={styles.topbar_second_button}>
                My descriptions
            </button>
        </Link>
    );

    const hostwriterLink = (
        <a
            href='/hostwriter'
            onClick={() => {
                sendGA4Event('clickhostWriter', {
                    timestamp: Date.now(),
                });
            }}
        >
            hostWriter
        </a>
    ); 

    const howItWorksLink = (
        <a
            href='/#how_it_work'
            onClick={() => {
                sendGA4Event('clickHowItWorks', {
                    timestamp: Date.now(),
                });
            }}
        >
            How it works
        </a>
    );

    const testimonialLink = (
        <a
            href='/#testimonals'
            onClick={() => {
                sendGA4Event('clickTestimonials', {
                    timestamp: Date.now(),
                });
            }}
        >
            Testimonials
        </a>
    );

    const joinWaitlistLink = (needBtn: boolean = true) => (
        <Link
            to='https://ksiacb3d3sj.typeform.com/to/jqXVXlJn'
            onClick={() => {
                sendGA4Event('clickTopBarJoinWaitList', {
                    timestamp: Date.now(),
                });
            }}
        >
            {needBtn ? joinWaitlistBtn : 'Join Waitlist'}
        </Link>
    );

    const joinWaitlistBtn = (
        <button className={styles.topbar_button}>
            Join Waitlist
        </button>
    );

    const mobileMenu = (
        <div className={styles.topbar_menu}>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className={styles.topbar_menu_btn}
            >
                <FontAwesomeIcon icon={faBars} size="2xl" style={{color: "#4e0a94",}} />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose} className={styles.alink}>
                    {hostwriterLink}
                </MenuItem>
                <MenuItem onClick={handleClose} className={styles.alink}>
                    {howItWorksLink}
                </MenuItem>
                <MenuItem onClick={handleClose} className={styles.alink}>
                    {testimonialLink}
                </MenuItem>
                <MenuItem onClick={handleClose} className={styles.alink}>
                    {joinWaitlistLink(false)}
                </MenuItem>
            </Menu>
        </div>
    );

    const defaultHostwriterButtons = (
        <>
            <div className={styles.topbar_hostwriter_links}>
            {loggedOn && loggedOnLinks}
            {!loggedOn && loggedOutLinks}
            </div>
        </>
    );

    const defaultButtons = (
        <>
            <div className={styles.topbar_links}>
                {hostwriterLink}
                {howItWorksLink}
                {testimonialLink}
            </div>
            <div className={styles.topbar_links} style={{ marginRight: '0' }}>
                {joinWaitlistLink()}
            </div>
            {mobileMenu}
        </>
    );
    return (
        <div className={styles.topbar}>
            <div className={styles.topbar_content}>
                <div className={styles.logo_wrapper}>
                    <a href='/'>
                        <img
                            src='/assets/icon/hostai.png'
                            alt='hostai logo'
                            className={styles.logo}
                        />
                    </a>
                </div> 
                <div className={styles.topbar_content_buttons}>
                    {hostWriter && defaultHostwriterButtons}
                    {!hostWriter && defaultButtons}
                </div>
            </div>
        </div>
    );
};

export default Topbar;
