import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import dashboardReducer from '../features/dashboard/Dashboard.slice';
import discriptorReducer from '../features/descriptor/Descriptor.slice';
import authReducer from '../features/auth/Auth.slice';
import commonReducer from '../features/common/Common.slice';
import propertyReducer from '../features/property/Property.slice';
import logger from 'redux-logger';
import { actionChainMiddleware }  from './action.chains';

export const store = configureStore({
    reducer: {
        common: commonReducer,
        auth: authReducer,
        dashboard: dashboardReducer,
        descriptor: discriptorReducer,
        property: propertyReducer,
    },
    middleware(getDefaultMiddlewares) {
        if (process.env.NODE_ENV !== 'production') {
            return getDefaultMiddlewares().concat([logger, actionChainMiddleware]);
        }
        else {
            return getDefaultMiddlewares().concat(actionChainMiddleware)
        }
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export const getStore = () => store;