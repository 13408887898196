import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPropertyDescrypt } from './Descriptor.api';
import { getExistingPropertyDescrypt } from './Descriptor.api';
import type { Descriptor, DescriptorState } from '../../models/StateTypes';

const initialState: DescriptorState = {
    status: 'idle',
    update_time: '',
    address: '',
    bathrooms: '',
    bedrooms: '',
    beds: '',
    max_occupancy: '',
    headline: '',
    summary: '',
    space: '',
    guest_access: '',
    other_things: '',
    old_description: {
        headline: '',
        summary: '',
        space: '',
        guest_access: '',
        other_things: '',
    },
    why_goods: {},
    annotations: [],
    submitValue: '',
};

export const descriptorSlice = createSlice({
    name: 'descriptor',
    initialState,
    reducers: {
        setDescriptor: (state, action: PayloadAction<Descriptor>) => {
            state.update_time = action.payload.update_time;
            state.address = action.payload.address;
            state.bathrooms = action.payload.bathrooms;
            state.bedrooms = action.payload.bedrooms;
            state.beds = action.payload.beds;
            state.max_occupancy = action.payload.max_occupancy;
            state.headline = action.payload.headline;
            state.summary = action.payload.summary;
            state.space = action.payload.space;
            state.guest_access = action.payload.guest_access;
            state.other_things = action.payload.other_things;
            state.old_description = action.payload.old_description;
            state.why_goods = action.payload.why_goods;
            state.annotations = action.payload.annotations;
        },
        setDescriptorSubmitValue: (state, action: PayloadAction<string>) => {
            state.submitValue = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getExistingPropertyDescrypt.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getExistingPropertyDescrypt.fulfilled, (state, action) => {
                if (action.payload) {
                    state.status = 'idle';
                    descriptorSlice.caseReducers.setDescriptor(state, action);
                } else {
                    state.status = 'notReady';
                }
            })
            .addCase(getExistingPropertyDescrypt.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(getPropertyDescrypt.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPropertyDescrypt.fulfilled, (state, action) => {
                state.status = 'idle';
                descriptorSlice.caseReducers.setDescriptor(state, action);
            })
            .addCase(getPropertyDescrypt.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const { setDescriptor, setDescriptorSubmitValue } =
    descriptorSlice.actions;

export default descriptorSlice.reducer;
