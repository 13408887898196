import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Loading.module.scss';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';

export const LoadingIcon = ({
    style,
    className,
}: {
    style?: any;
    className?: string;
}) => {
    return (
        <div className={`${styles.loading_spinner} ${className}`} style={style}>
            <FontAwesomeIcon icon={faSpinner} />
        </div>
    );
};
