import { createSelector } from '@reduxjs/toolkit';
import { AuthState, CommonState, PropertyState } from '../models/StateTypes';
import type { RootState } from '../app/store';

export const selectAuth = (state: RootState) => state.auth;
export const selectAuthStatus = createSelector(selectAuth, (auth: AuthState) => auth.status);
export const selectIsLoggedOn = createSelector(selectAuth, (auth: AuthState) => auth.loggedOn);
export const selectAuthProperties = createSelector(selectAuth, (auth: AuthState) => auth.properties);
export const selectSubscription = createSelector(selectAuth, (auth: AuthState) => auth.subscription);
export const selectTempEmail = createSelector(selectAuth, (auth: AuthState) => auth.tempLoginEmail);
export const selectAccountId = createSelector(selectAuth, (auth: AuthState) => auth.uid);

export const selectCommon = (state: RootState) => state.common;
export const selectMessage = createSelector(selectCommon, (common: CommonState) => {
    return { 
        message: common.message,
        messageType: common.messageType
    };
});

export const selectAdSpend = (state: RootState) => state.dashboard.adSpend;
export const selectUsers = (state: RootState) => state.dashboard.users;
export const selectRevenue = (state: RootState) => state.dashboard.revenue;
export const selectPrevCount = (state: RootState) => state.dashboard.users;

export const selectDescriptor = (state: RootState) => state.descriptor;

export const selectProperty = (state: RootState) => state.property;

export const selectPropertiesForOnboarding = createSelector(
    selectProperty,
    selectAuth,
    (property: PropertyState, auth: AuthState) => {
        if (property.properties.length > 0 ) {
            return property.properties;
        }
        return auth.properties;
    });
