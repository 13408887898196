import styles from './TotalPrice.module.scss';
import { ALL_PROPERTIES, MONTH } from '../../../utils/Constants';
import {
    getFullPrice,
    getFullUnitPrice,
    getDiscountedPrice,
    getUnitPrice,
} from '../../../utils/commonUtils';

const TotalPrice = ({
    propertyType = ALL_PROPERTIES,
    plan = MONTH,
    numOfListing = 1,
}: {
    propertyType: string;
    plan: string;
    numOfListing: number;
}) => {
    return (
        <div className={styles.total_summary}>
            <div className={styles.total_summary_choice}>
                <span>
                    {propertyType === ALL_PROPERTIES
                        ? `Per property`
                        : 'Single Property'}
                </span>
                <span className={styles.span_order}>
                    <span className={styles.crossed_out}>
                        {getFullUnitPrice(plan)}
                    </span>
                    {getUnitPrice(plan)}
                </span>
            </div>
            <div className={styles.total_summary_price}>
                <span>Total ({numOfListing})</span>
                <span className={styles.span_order}>
                    <span className={styles.crossed_out}>
                        {getFullPrice({
                            type: propertyType,
                            plan: plan,
                            numOfListing: numOfListing,
                        })}
                    </span>
                    {getDiscountedPrice({
                        type: propertyType,
                        plan: plan,
                        numOfListing: numOfListing,
                    })}
                </span>
            </div>
        </div>
    );
};

export default TotalPrice;
