import styles from './BillingType.module.scss';
import {
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { MONTH, YEAR } from '../../../utils/Constants';

const BillingType = ({
    plan = MONTH, 
    onChangeAction
}: {plan: string, onChangeAction: (event: React.ChangeEvent<HTMLInputElement>) => void}) => {
    return (
        <div className={styles.billing_type_radio_wrapper}>
            <RadioGroup className={styles.billing_type_radio}
                value={plan}
                onChange={onChangeAction}
            >
                <FormControlLabel
                    className={`${
                        styles.onboarding_section_content_radio_control
                    } ${
                        MONTH === plan? styles.radio_active: ''
                    }`}
                    value={MONTH}
                    control={<Radio />}
                    label='Monthly'
                />
                <FormControlLabel
                    className={`${
                        styles.onboarding_section_content_radio_control
                    } ${
                        YEAR === plan? styles.radio_active : ''
                    }`}
                    value={YEAR}
                    control={<Radio />}
                    label='Yearly (-33%)'
                />
            </RadioGroup>
        </div>
        );
    };
    
    export default BillingType;
