import './App.css';

import { RouterProvider } from 'react-router-dom';
import Descriptor from './descriptor/Descriptor';
import { auth } from './firebase';
import useClarity, {
    useAppDispatch,
    useAppSelector,
    useGA4,
} from './app/hooks';
import { onAuthStateChanged } from 'firebase/auth';
import { failLocalLogin, refreshLogin } from './features/auth/Auth.slice';
import { AppRouter } from './AppRouter';
import { selectIsLoggedOn, selectMessage } from './features/selectors';

let i = 0;

function App() {
    const { message, messageType } = useAppSelector(selectMessage);
    const loginStatus = useAppSelector(selectIsLoggedOn);
    const dispatch = useAppDispatch();
    useClarity(process.env.REACT_APP_CLARITY_PROJECT_ID ?? '');
    useGA4(process.env.REACT_APP_GA4_ID ?? '');
    if (i === 0) {
        onAuthStateChanged(auth, (user: any) => {
            if (user && !loginStatus) {
                dispatch(
                    refreshLogin({
                        accessToken: user.accessToken,
                        email: user.email,
                        uid: user.uid,
                    })
                );
            } else if (!user) {
                dispatch(failLocalLogin());
            }
        });
        i += 1;
    }

    return (
        <div className='App'>
            <RouterProvider
                router={AppRouter}
                fallbackElement={<Descriptor />}
            />
            <div
                className={`global_message_wrapper ${
                    message ? 'show' : 'hide'
                }`}
            >
                <div className={`global_message ${messageType}`}>{message}</div>
            </div>
        </div>
    );
}

export default App;
