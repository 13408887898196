import React, { useEffect } from 'react';
import { useAppSelector } from '../app/hooks';
import { useNavigate } from 'react-router-dom';
import { selectAuthStatus, selectIsLoggedOn } from '../features/selectors';

const LoginAuthGuard = ({ children, redirect } : any) => {
    const loggedOn = useAppSelector(selectIsLoggedOn)
    const initStatus = useAppSelector(selectAuthStatus);
    const navigator = useNavigate()

    useEffect(() => {
        if (initStatus === 'finished') {
            if (!loggedOn) {
                navigator(redirect)
            }
        }
    }, [loggedOn, initStatus]);

    return <React.Fragment>{children}</React.Fragment>
}

const LogoutAuthGuard = ({ children, redirect } : any) => {
    const loggedOn = useAppSelector(selectIsLoggedOn)
    const initStatus = useAppSelector(selectAuthStatus);
    const navigator = useNavigate()

    useEffect(() => {
        if (initStatus === 'finished') {
            if (loggedOn) {
                navigator(redirect)
            }
        }
    }, [loggedOn, initStatus]);

    return <React.Fragment>{children}</React.Fragment>
}

export {
    LoginAuthGuard,
    LogoutAuthGuard,
}