import { createBrowserRouter } from 'react-router-dom';
import Descriptions from './descriptor/Descriptions';
import Descriptor from './descriptor/Descriptor';
import LogginIn from './auth/LogginIn';
import Login from './auth/Login';
import Signup from './auth/Signup';
import CheckEmail from './auth/CheckEmail';
import Onboarding from './onboarding/Onboarding';
import { LoginAuthGuard, LogoutAuthGuard } from './auth/LoginAuthGaurd';
import SettingsWrapper from './descriptor/SettingsWrapper';

export const AppRouter = createBrowserRouter([
    {
        path: '/',
        element: (
            <LoginAuthGuard redirect='/login'>
                <Descriptions />
            </LoginAuthGuard>
        ),
    },
    {
        path: '/descriptions',
        element: (
            <LoginAuthGuard redirect='/login'>
                <Descriptions />
            </LoginAuthGuard>
        ),
    },
    {
        path: '/settings',
        element: (
            <LoginAuthGuard redirect='/login'>
                <SettingsWrapper />
            </LoginAuthGuard>
        ),
    },
    {
        path: '/hostwriter',
        element: <Descriptor />,
    },
    {
        path: '/airbnb_description_generator',
        element: <Descriptor />,
    },
    {
        path: '/onboarding',
        element: <Onboarding />,
    },
    {
        path: '/login/callback',
        element: (
            <LogoutAuthGuard redirect='/'>
                <LogginIn />
            </LogoutAuthGuard>
        ),
    },
    {
        path: '/login',
        element: (
            <LogoutAuthGuard redirect='/'>
                <Login />
            </LogoutAuthGuard>
        ),
    },
    {
        path: '/signup',
        element: (
            <LogoutAuthGuard redirect='/'>
                <Signup />
            </LogoutAuthGuard>
        ),
    },
    {
        path: '/sentEmail',
        element: (
            <LogoutAuthGuard redirect='/'>
                <CheckEmail />
            </LogoutAuthGuard>
        ),
    },
]);

export const getRouter = () => AppRouter;
