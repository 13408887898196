import React, { useState } from 'react';
import styles from './DescriptionOnly.module.scss';
import DescriptionSection from '../DescriptionSection/DescriptionSection';
import { DescriptorState } from '../../../models/StateTypes';

type Annotation = {
    phrase: string;
    type: string;
};

const ANNOTATION_TYPES_COLOR: Record<string, string> = {
    AMENITY: '#0ea5e9',
    POI: '#8b5cf6',
    SEASON: '#91ee8f',
    HOLIDAY: '#91ee8f',
    SALES_PITCH: '#f43f5e',
    TARGET_AUDIENCE: '#f59e0b',
    PURPOSE_OF_TRAVEL: '#f59e0b',
    HOUSE_RULES: '#FFE4E6',
    RESTAURANT: 'lightgoldenrodyellow',
};

const ANNOTATION_TYPES_COLOR_LIGHT: Record<string, string> = {
    AMENITY: '#e0f2fe',
    POI: '#ede9fe',
    SEASON: '#defadd',
    HOLIDAY: '#defadd',
    SALES_PITCH: '#ffe4e6',
    TARGET_AUDIENCE: '#fef3c7',
    PURPOSE_OF_TRAVEL: '#fef3c7',
    HOUSE_RULES: '#FFE4E6',
    RESTAURANT: 'lightgoldenrodyellow',
};

const ANNOTATION_TYPES_LABEL: Record<string, string> = {
    AMENITY: 'Amenities',
    POI: 'Points of interest',
    SEASON: 'Season',
    HOLIDAY: 'Holidays',
    SALES_PITCH: 'Sales pitch',
    TARGET_AUDIENCE: 'Target audience',
    PURPOSE_OF_TRAVEL: 'Purpose of travel',
    HOUSE_RULES: 'House rules',
    RESTAURANT: 'Restaurant',
};

const toggleLabels = Object.keys(ANNOTATION_TYPES_LABEL);

const escapeRegExp = (str: string): string => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

interface DescriptionOnlyProps {
    data: DescriptorState;
    isDemo: boolean;
}

const DescriptionOnly: React.FC<DescriptionOnlyProps> = ({ data, isDemo }) => {
    const [toggles, setToggles] = useState(
        Array(toggleLabels.length).fill(true)
    );

    const handleToggleChange = (index: number) => () => {
        const newToggles = [...toggles];
        newToggles[index] = !newToggles[index];
        setToggles(newToggles);
    };
    const highlightToggles = Object.keys(ANNOTATION_TYPES_COLOR).map(
        (label, index) => (
            <button
                key={index}
                className={`${styles.toggleButton} ${
                    toggles[index] ? styles.toggleButtonSelected : ''
                }`}
                onClick={handleToggleChange(index)}
            >
                <span
                    className={styles.colorIndicator}
                    style={{
                        backgroundColor: `${ANNOTATION_TYPES_COLOR[label]}`,
                    }}
                ></span>
                <div className={styles.toggleButtonText}>
                    {ANNOTATION_TYPES_LABEL[label]}
                </div>
            </button>
        )
    );

    const sectionTitles = [
        'Headline',
        'Description summary',
        'The space',
        'Guest access',
        'Other things to note',
    ];

    const togglesOn = toggleLabels.filter((_, index) => toggles[index]);

    const applyHighlight = (
        text: string,
        annotations: Annotation[] | undefined
    ): string => {
        let highlightedText = text;

        (annotations ?? []).forEach((anno) => {
            // const color = `${ANNOTATION_TYPES_COLOR[anno.type]}50`;
            const color = `${ANNOTATION_TYPES_COLOR_LIGHT[anno.type]}`;
            const regex = new RegExp(
                // `(?<!\\w)${escapeRegExp(anno.phrase)}(?!n?\\w)`,
                `(\\b|^)${escapeRegExp(anno.phrase)}(\\b|$)`,
                'gi'
            );

            const highlightedSubstring = `<span style="background-color: ${color};" title="${anno.type}">${anno.phrase}</span>`;
            if (togglesOn.includes(anno.type)) {
                highlightedText = highlightedText.replace(
                    regex,
                    highlightedSubstring
                );
            }
        });

        return highlightedText;
    };

    const sectionDescriptions = [
        isDemo
            ? applyHighlight(data.headline, data.annotations)
            : data.headline,
        isDemo ? applyHighlight(data.summary, data.annotations) : data.summary,
        isDemo ? applyHighlight(data.space, data.annotations) : data.space,
        isDemo
            ? applyHighlight(data.guest_access, data.annotations)
            : data.guest_access,
        isDemo
            ? applyHighlight(data.other_things, data.annotations)
            : data.other_things,
    ];
    const whyGoodList = [
        data?.why_goods?.['headline'] ?? [],
        data?.why_goods?.['summary'] ?? [],
        data?.why_goods?.['space'] ?? [],
        data?.why_goods?.['guest_access'] ?? [],
        data?.why_goods?.['other_things'] ?? [],
    ];

    const oldDescriptions = [
        data?.old_description?.headline ?? '',
        data?.old_description?.summary ?? '',
        data?.old_description?.space ?? '',
        data?.old_description?.guest_access ?? '',
        data?.old_description?.other_things ?? '',
    ];

    const descriptions = sectionTitles.map((title, index) => (
        <DescriptionSection
            key={index}
            title={title}
            description={sectionDescriptions[index]}
            oldDescription={oldDescriptions[index]}
            isDemo={isDemo}
            whyGoods={whyGoodList[index].map((item, index) => (
                <li key={index} style={{ marginTop: '12px' }}>
                    {item.title}: {item.detail}
                </li>
            ))}
        />
    ));

    return (
        <div className={styles.description_only}>
            {isDemo && data.annotations && (
                <div className={styles.description_section}>
                    <div className={styles.description_title}>
                        Highlighting
                    </div>
                    <div className={styles.buttons_wrapper}>
                        {highlightToggles}
                    </div>
                </div>
            )}
            {descriptions}
        </div>
    );
};

export default DescriptionOnly;
