import Topbar from './components/Topbar/Topbar';
import Properties from './components/Properties/Properties';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRouter } from '../AppRouter';
import { sendGA4Event, useAppSelector } from '../app/hooks';
import {
    selectAuthProperties,
    selectSubscription,
} from '../features/selectors';
import styles from './Descriptions.module.scss';
import { AlertDialog } from '../common/components/Dialogs/AlertDialog';
import { MOBILE_ALERT, breakpoints } from '../utils/Constants';
import PaymentCallback from './PaymentCallback';

const Descriptions: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [showPaymentCallback, setShowPaymentCallback] = useState(
        !!searchParams.get('session_id')
    );
    const windowSize = useRef([window.innerWidth]);
    const alertMessage = MOBILE_ALERT;
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

    const openAlertModal = () => {
        setIsAlertModalOpen(true);
    };

    const closeAlertModal = () => {
        setIsAlertModalOpen(false);
    };

    useEffect(() => {
        if (windowSize.current[0] < breakpoints.sm) {
            openAlertModal();
        }
    }, []);

    const properties = useAppSelector(selectAuthProperties);
    const subscription = useAppSelector(selectSubscription);
    useEffect(() => {
        // NOTE: this logic depends on the assumption that any account must have an email once they are logged in
        // If email is non-empty and subscription.status is the this user hasn't signed up yet.
        if (
            !showPaymentCallback &&
            subscription.email &&
            subscription.status === ''
        ) {
            AppRouter.navigate('/onboarding');
        }
    }, [subscription.email, showPaymentCallback]);

    const handleSuccessCheckout = () => {
        setShowPaymentCallback(false);
    };

    const handleTabSelect = (tab: 'properties' | 'settings') => {
        if (tab === 'properties') {
            navigate('/descriptions');
        } else {
            navigate('/settings');
        }
    };
    sendGA4Event('viewDescriptionsPage', { timestamp: Date.now() });

    if (showPaymentCallback)
        return <PaymentCallback onSuccess={handleSuccessCheckout} />;

    return (
        <div className={styles.descriptions_wrapper}>
            <Topbar onTabSelect={handleTabSelect} />
            <Properties properties={properties} />

            <AlertDialog
                isAlertModalOpen={isAlertModalOpen}
                onClose={closeAlertModal}
                alertMessage={alertMessage}
            />
        </div>
    );
};

export default Descriptions;
