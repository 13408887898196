import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';
import { sendGA4Event } from '../../../app/hooks';

const Footer = () => {
    return (
        <div className={styles.footer_wrapper}>
            <div className={styles.footer}>
                <div className={styles.footer_content}>
                    <img
                        src='/assets/icon/hostai.png'
                        alt='hostai logo'
                        className={styles.logo}
                    />
                    <p>
                        AI-Powered Direct Revenue Growth for Vacation Rental
                        Managers
                    </p>
                    <Link
                        to='https://ksiacb3d3sj.typeform.com/to/jqXVXlJn'
                        onClick={() => {
                            sendGA4Event('clickJoinWaitListFooter', {
                                timestamp: Date.now(),
                            });
                        }}
                    >
                        <button className={styles.footer_content_primary_btn}>
                            Join Waitlist
                        </button>
                    </Link>
                </div>
                <div className={styles.footer_content}>
                    {/* <div className={styles.footer_content_title}>Clients</div>
          <Link to="https://bookhostai.com/openairhomes">Open Air Homes</Link>
          <Link to="https://bookhostai.com/stay_pittsburgh">
            Stay Pittsburgh
          </Link>
          <Link to="https://bookhostai.com/blue_maple">Blue Maples</Link> */}
                </div>
                <div className={styles.footer_content}>
                    <div className={styles.footer_content_title}>Company</div>
                    {/* <Link to="">Team</Link> */}
                    <Link
                        to='https://share-docs.clickup.com/25578335/d/h/rcjuz-1500/22c88cb8f8caaed'
                        onClick={() => {
                            sendGA4Event('clickCareer', {
                                timestamp: Date.now(),
                            });
                        }}
                    >
                        Career
                    </Link>
                    <Link
                        to='https://share-docs.clickup.com/25578335/d/h/rcjuz-2207/78219b00d5d2e71'
                        onClick={() => {
                            sendGA4Event('clickPrivacyPolicy', {
                                timestamp: Date.now(),
                            });
                        }}
                    >
                        Privacy Policy
                    </Link>
                    <Link
                        to='https://share-docs.clickup.com/25578335/d/h/rcjuz-2227/3513355be586204'
                        onClick={() => {
                            sendGA4Event('clickTOS', { timestamp: Date.now() });
                        }}
                    >
                        Legal Terms of Service
                    </Link>
                    <div className={styles.footer_contact}>
                        <a href='mailto:contact@gethostai.com'>
                            contact@gethostai.com
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
