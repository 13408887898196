import styles from './LogoDialog.module.scss';

export const LogoDialog = ({ children, style }: { children: React.ReactNode, style?: any }) => {
    return (
        <div className={styles.LogoDialog} style={style}>
            <div>
                <img
                    src='/assets/icon/hostai.png'
                    alt='hostai logo'
                    className={styles.LogoDialog_logo}
                />
            </div>
            {children}
        </div>);
}
