import { createSlice } from "@reduxjs/toolkit";
import { getAirbnbProfileAsync } from "./Property.api";
import { PropertyState } from "../../models/StateTypes";


const initialState: PropertyState = {
    status: 'idle',
    properties: [],
};

export const propertySlice = createSlice({
    name: 'property',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
        .addCase(getAirbnbProfileAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getAirbnbProfileAsync.rejected, (state) => {
            state.status = 'idle';
        })
        .addCase(getAirbnbProfileAsync.fulfilled, (state, action) => {
            state.status = 'idle';
            state.properties = action.payload?.properties ?? [];
        })
    },
});

export default propertySlice.reducer;
