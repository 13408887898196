export const ALL_PROPERTIES = 'all properties';
export const ONE_PROPERTY = 'one property';
export const MONTH = 'month';
export const YEAR = 'year';
export const breakpoints = {
    xl: 1280,
    lg: 1024,
    md: 768,
    sm: 640,
    xs: 393,
};
export const MOBILE_ALERT =
    'For the best experience, we recommend using hostWriter on a desktop or laptop computer. You may encounter some limitations while using it on mobile devices.';
export const PAYMENT_CALLBACK_INTERVAL = 4000;
