import React, { useState } from 'react';
import PropertyCard from './PropertyCard';
import styles from './PropertyIndex.module.scss';
import Property from '../../../common/types/Property';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface PropertyIndexProps {
    properties: Property[];
    onPropertySelect: (property: Property) => void;
    isSetting: boolean;
}

const PropertyIndex: React.FC<PropertyIndexProps> = ({
    properties,
    onPropertySelect,
    isSetting,
}) => {
    const [search, setSearch] = useState('');

    const filteredProperties = properties.filter(
        (property) =>
            (property.title ?? '')
                .toLowerCase()
                .includes(search.toLowerCase()) ||
            (property.address ?? '')
                .toLowerCase()
                .includes(search.toLowerCase()) ||
            property.pid.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <div>
            {!isSetting && (
                <div className={styles.search_box}>
                    <FontAwesomeIcon icon={faSearch} className={styles.icon} />
                    <input
                        className={styles.input}
                        type='text'
                        placeholder='Search...'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            )}
            <div className={styles.property_index_wrapper}>
                {properties &&
                    filteredProperties.map((property) => (
                        <PropertyCard
                            key={property.pid}
                            property={{
                                pid: property.pid,
                                title: `${property.title}`,
                                address: `${property.address}`,
                                photo_url:
                                    `${property.photo_url}`,
                            }}
                            onPropertySelect={onPropertySelect}
                        />
                    ))}
                {!properties && <div>Loading properties list...</div>}
            </div>
        </div>
    );
};

export default PropertyIndex;
