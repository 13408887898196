import axios from 'axios';

const BASE_URL = '/api';

const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const setAccessToken = (token: string) => {
    instance.defaults.headers['Authorization'] = `Bearer ${token}`;
}

class AxiosClient {
    get(url: string, params = {}) {
        return instance({
            url,
            method: 'GET',
            params: {
                ...params,
                // t: +(new Date()),
            },
        });
    }

    put(url: string, data: any) {
        return instance({
            url,
            data,
            method: 'put',
        });
    }

    post(url: string, data: any) {
        return instance({
            url,
            data,
            method: 'POST',
        });
    }

    patch(url: string, data: any) {
        return instance({
            url,
            data,
            method: 'PATCH',
        });
    }

    delete(url: string) {
        return instance({
            url,
            method: 'DELETE',
        });
    }
}

export default new AxiosClient();
