import styles from './ChangePlan.module.scss';
import React, { useEffect, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { sendGA4Event, useAppDispatch } from '../../../app/hooks';
import { changeSubscriptionAsync, confirmPayAsync, updatePlanTypeAsync } from '../../../features/auth/Auth.api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { selectAccountId, selectPropertiesForOnboarding, selectSubscription } from '../../../features/selectors';

import {
    SelectChangeEvent,
} from '@mui/material';
import { ALL_PROPERTIES, MONTH } from '../../../utils/Constants';

import PropertyIndex from '../Properties/PropertyIndex';
import { ErrorDialog } from '../../../common/components/Dialogs/ErrorDialog';
import { useNavigate } from 'react-router-dom';
import PropertyType from '../../../common/components/Billing/PropertyType';
import BillingType from '../../../common/components/Billing/BillingType';
import TotalPrice from '../../../common/components/Billing/TotalPrice';

const ChangePlan = ({onClose, isChangePlan} : {onClose: ()=>void, isChangePlan: Boolean}) => {
    const closeChangePlanModal = () => {
        onClose();
    }
    const [propertiesChoice, setPropertiesChoice] = useState(ALL_PROPERTIES);
    const [billingChoice, setBillingChoice] = useState(MONTH);

    const [stripe, setStripe] = useState<Stripe | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const properties = useSelector(selectPropertiesForOnboarding)
    const accountId = useSelector(selectAccountId)
    const subscription = useSelector(selectSubscription)

    const numListings = properties.length;
    const dispatch = useAppDispatch();
    const airbnbUrl = `https://airbnb.com/rooms/${subscription.main_property}`;

    useEffect(() => {
        const loadStripeLibrary = async () => {
            const stripeInstance = await loadStripe(
                process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? ''
            );
            setStripe(stripeInstance);
        };

        loadStripeLibrary();
    }, []);

    const navigate = useNavigate();
    const openErrorModal = (errorMessage: string) => {
        setErrorMessage(errorMessage);
        setIsErrorModalOpen(true);
    };

    const closeErrorModal = () => {
        setErrorMessage('');
        setIsErrorModalOpen(false);
    };

    const handlePropertiesChoiceChange = (
        event: SelectChangeEvent<string>
    ) => {
        setPropertiesChoice(event.target.value);
    };

    const handleBillingChoiceChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setBillingChoice(event.target.value);
    };

    const filteredProperties = properties.filter((item) => {
        if (propertiesChoice === ALL_PROPERTIES) {
            return true;
        } else if (item.pid === subscription.main_property) {
            return true; 
        }
        return false;
    });

    const handleClickConfirm = async () => {
        sendGA4Event('clickConfirm', { account_id: accountId, url: airbnbUrl, timestamp: Date.now(),
            plan_interval: billingChoice, plan_type: propertiesChoice, num_properties: numListings });
        const confirmPayAction = await dispatch(changeSubscriptionAsync({plan_interval: billingChoice, account_id: accountId }));

        if (confirmPayAction.type === 'auth/changeSubscription/fulfilled') {
            await dispatch(updatePlanTypeAsync({planType: propertiesChoice, accountId }));
            navigate('/descriptions');
        }
    };

    const handleClickConfirmAndPay = async () => {
        sendGA4Event('clickConfirmAndPay', { account_id: accountId, url: airbnbUrl, timestamp: Date.now(),
            plan_interval: billingChoice, plan_type: propertiesChoice, num_properties: numListings });
        const confirmPayAction = await dispatch(confirmPayAsync({planInterval: billingChoice, accountId }));

        if (confirmPayAction.type === 'auth/confirmPay/fulfilled') {
            const updatePlanAction = await dispatch(updatePlanTypeAsync({planType: propertiesChoice, accountId }));
            if (updatePlanAction.type === 'auth/updateaPlanType/fulfilled') {
                if (updatePlanAction.payload === 'ok' && stripe) {
                    stripe.redirectToCheckout({
                        sessionId: confirmPayAction.payload,
                    });
                    return;
                }
            }
        }
        openErrorModal(
            'Please provide a valid Airbnb url'
        );
    };
    
    return (
        <div className={styles.change_plan_wrapper}>
            <div className={styles.change_plan_title_div}>
                <div className={styles.change_plan_title}>
                    {isChangePlan ? 'Change Plan' : 'Renew Plan'}
                </div>
                <div className={styles.change_plan_btnX}>
                    <FontAwesomeIcon icon={faXmark} onClick={closeChangePlanModal}/>
                </div>
            </div>
            <div className={styles.change_plan_details}>
                <div className={styles.properties}>
                    <div className={styles.properties_title_div}>
                        <div className={styles.properties_title}>
                            {propertiesChoice === ALL_PROPERTIES ? 'Your properties' : 'Selected property'}
                        </div>
                        <div className={styles.properties_count}>
                            {filteredProperties.length} total
                        </div>
                    </div>
                    <div className={`${styles.properties_list_div} properties_list_div`}>
                        <PropertyIndex
                            properties={filteredProperties}
                            onPropertySelect={() => {}}
                            isSetting={true}
                        />
                    </div>
                </div>
                <div className={styles.div_seperate}></div>
                <div className={styles.billing}>
                    <div className={styles.billing_title}>
                        Billing
                    </div>
                    <BillingType 
                        plan={billingChoice}
                        onChangeAction={handleBillingChoiceChange} 
                    />
                </div>
                <div className={styles.total_info}>
                    <div className={styles.total_info_title}>
                        Total
                    </div>
                    <TotalPrice 
                        propertyType={propertiesChoice} 
                        plan={billingChoice} 
                        numOfListing={numListings} />
                </div>
                <div>
                { isChangePlan && (
                    <button 
                        className={styles.confirm}
                        onClick={handleClickConfirm}>
                        Confirm
                    </button>
                )}
                { !isChangePlan && (
                    <button 
                        className={styles.confirm}
                        onClick={handleClickConfirmAndPay}>
                        Confirm & Pay
                    </button>
                )}
                </div>
            </div>
            <ErrorDialog 
                isErrorModalOpen={isErrorModalOpen}
                onClose={closeErrorModal} 
                errorMessage={errorMessage} 
            />
        </div>
    );
};

export default ChangePlan;