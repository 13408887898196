import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import axios from '../../utils/AxiosClient';

const actionCodeSettings = {
    url: process.env.REACT_APP_FIREBASE_APP_LOGIN_CALLBACK_URL ?? 'https://gethostai.com/login/callback',
    handleCodeInApp: true,
}

export const loginAsync = createAsyncThunk(
    'auth/login',
    async ({ id }: {id: string}) => {
        await sendSignInLinkToEmail(auth, id, actionCodeSettings)
        window.localStorage.setItem('emailForSignIn', id);
        return;
    }
);

export const loginVerifyAsync = createAsyncThunk(
    'auth/loginVerify',
    async () => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                throw new Error('Stored Email is not exist'); 
            } 

            const userCredential: any = await signInWithEmailLink(auth, email, window.location.href)

            window.localStorage.removeItem('emailForSignIn');
            return {
                accessToken: userCredential?.user?.accessToken ?? '',
                email: userCredential?.user?.email ?? '',
                uid: userCredential?.user?.uid ?? '',
            }
        }
    }
);

export const signupAsync = createAsyncThunk(
    'auth/signup',
    async ({ id }: { id: string }) => {
        // const userCredential: any = await createUserWithEmailAndPassword(auth, id, pw) 
        // return {
        //     accessToken: userCredential.user.accessToken,
        //     email: userCredential.user.email,
        //     uid: userCredential.user.uid,
        // }
        await sendSignInLinkToEmail(auth, id, actionCodeSettings)
        window.localStorage.setItem('emailForSignIn', id);
        return;
    }
);

export const logoutAsync = createAsyncThunk(
    'auth/logout',
    async () => {
        await signOut(auth) 
        return
    }
);

export const fetchAccountInfoAsync = createAsyncThunk(
    'auth/fetchAccountInfo',
    async (accountId: string) => {
        const result = await axios.post('/get_account', {
            account_id: accountId,
            flavor: process.env.REACT_APP_BUILD_ENV,
        })
        console.log('result.data:');
        console.log(result.data);
        return result.data;
    }
);

export const updateEmailAsync = createAsyncThunk(
    'auth/updateEmail',
    async ({email, account_id }: {email: string, account_id: string}) => {
        const result = await axios.post('/set_account_email', {
            email,
            account_id,
        })

        return result.data;
    }
);

export const setSubscriptionTypeAsync = createAsyncThunk(
    'auth/setSubscriptionType',
    async ({ subscription_type, account_id } : { subscription_type: string, account_id: string }) => {
        const result = await axios.post('/set_subscription_type', {
            subscription_type,
            account_id,
        });

        return result.data
    }
);

export const changeSubscriptionAsync = createAsyncThunk(
    'auth/changeSubscription',
    async ({plan_interval, account_id }: {plan_interval: string, account_id: string}) => {
        const result = await axios.post('/change_subscription', {
            plan_interval,
            account_id,
        })

        return result.data;
    }
);

export const cancelSubscriptionAsync = createAsyncThunk(
    'auth/cancelSubscription',
    async (account_id: string) => {
        const result = await axios.post('/cancel_subscription', {
            account_id,
        })

        return result.data;
    }
);

export const createAccountInfoAsync = createAsyncThunk(
    'auth/createAccountInfo',
    async ({account_id, email}: {account_id: string, email: string}) => {
        const result = await axios.post('/create_account', {
            account_id,
            email,
        })

        return result.data;
    }
);

export const confirmPayAsync = createAsyncThunk(
    'auth/confirmPay',
    async ({ planInterval, accountId, numListings }: {planInterval: string, accountId: string, numListings?: number}) => {
        const forTesting = !!process.env.REACT_APP_IS_LOCAL ?? false;

        const res = await axios.post('/create_checkout_session', {
            plan_interval: planInterval,
            account_id: accountId,
            num_properties: numListings,
            for_testing: forTesting,
        });

        return res.data;
    },
);

export const updatePlanTypeAsync = createAsyncThunk(
    'auth/updateaPlanType',
    async ({ accountId, planType }: {accountId: string, planType: string}) => {
        const res = await axios.post('/update_plan_type', {
            plan_type: planType,
            account_id: accountId,
        });

        return res.data;
    },
);

export const updateSyncWithPmsAsync = createAsyncThunk(
    'auth/updateSyncWithPms',
    async ({ accountId, syncWithPms, pmsName }: {accountId: string, syncWithPms: boolean, pmsName: string}) => {
        const response = await axios.post('/update_sync_with_pms', {
            account_id:accountId, 
            should_deploy_to_pms: syncWithPms,
            pms_name: pmsName,
        });
        return response.data;
    }
);

export const createCredentialsAsync = createAsyncThunk(
    'auth/createCredentials',
    async ({ accountId, token, pmsName, clientId }:{token: string, accountId:string, pmsName:string, clientId:string}) => {
        const response = await axios.post('/create_credentials', { account_id: accountId, integration_token: token, pms_name: pmsName, api_client_id:clientId});
        return response.data;
    }
);
