import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/AxiosClient';

export const getAirbnbProfileAsync = createAsyncThunk(
    'property/getAirbnbProfile',
    async ({airbnbUrl, accountId}: {airbnbUrl: string, accountId: string}) => {
        const { data } = await axios.post('/airbnb_profile', {
            url: airbnbUrl, account_id: accountId,
        });

        return data;
    }
);
