import styles from './Settings.module.scss';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { Dialog } from '@mui/material';
import Subscription from '../../../common/types/Subscription';
import { ALL_PROPERTIES, YEAR } from '../../../utils/Constants';
import { useAppDispatch } from '../../../app/hooks';
import { cancelSubscriptionAsync } from '../../../features/auth/Auth.api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import ChangePlan from './ChangePlan';
import { IntegrationToggle } from './IntegrationToggle';
import { IntegrationTokenDialog } from './IntegrationTokenDialog';

interface SettingsProps {
    account_id: string;
    subscription: Subscription;
}

const Settings: React.FC<SettingsProps> = ({ account_id, subscription }) => {

    // HHM: added for pms 
    const [isTokenDialogOpen, setIsTokenDialogOpen] = useState(false);
    const [selectedPmsName, setSelectedPmsName] = useState('');
    const handleIntegrationToggle = (enabled: boolean, pmsName: string) => {
        if (enabled) {
            setIsTokenDialogOpen(true);
            setSelectedPmsName(pmsName);
        }
    };

    const closeTokenDialog = () => {
        setIsTokenDialogOpen(false);
    };
    //console.log('Settings: subscription=', subscription);
    const pmsIntegrationControls = (
        <>
            <IntegrationToggle pmsName='guesty' initialChecked={subscription.pms_name == 'guesty' && subscription.pms_sync_active} onToggle={(enabled) => handleIntegrationToggle(enabled, 'guesty')} accountId={account_id} />
            <IntegrationToggle pmsName='hostaway' initialChecked={subscription.pms_name == 'hostaway' && subscription.pms_sync_active} onToggle={(enabled) => handleIntegrationToggle(enabled, 'hostaway')} accountId={account_id} />
            <IntegrationTokenDialog isOpen={isTokenDialogOpen} onClose={closeTokenDialog} accountId={account_id} pmsName={selectedPmsName} />
        </>
    );
    // \\ HHM: added for pms 

    const dispatch = useAppDispatch()
    const isAllPropertyPlan = subscription.type === ALL_PROPERTIES;

    const isAnnualSubscription = subscription.plan_interval === YEAR;
    const isSubscriber = subscription.status !== 'canceled';
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isChangePlanModalOpen, setIsChangePlanModalOpen] = useState(false);

    const lastUpdateDate = new Date(subscription.update_time);
    if (subscription.plan_interval === 'month') {
        lastUpdateDate.setMonth(lastUpdateDate.getMonth() + 1);
    } else if (subscription.plan_interval === 'year') {
        lastUpdateDate.setFullYear(lastUpdateDate.getFullYear() + 1);
    }
    const nextBillingDate = lastUpdateDate.toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "numeric" });

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openChangePlanModal = () => {
        setIsChangePlanModalOpen(true);
    }

    const closeChangePlanModal = () => {
        setIsChangePlanModalOpen(false);
    }

    const cancelSubscription = () => {
        dispatch(cancelSubscriptionAsync(account_id)).then(() => {
            closeModal();
        });
    };


    const emailSection = (
        <div className={styles.settings_section}>
            <div className={styles.settings_section_header}>
                Account
            </div>
            <div className={styles.settings_section_body}>
                <div className={styles.form_element}>
                    <label htmlFor="email" className={styles.form_label}>
                        Email
                    </label>
                    <div className={styles.form_control}>
                        <input
                            type='email'
                            id='email'
                            value={subscription.email}
                            disabled
                        />
                    </div>
                    <p className={styles.form_description}>
                        <FontAwesomeIcon
                            size="sm"
                            icon={faInfoCircle}
                        />
                        Please contact support to change your email address
                    </p>
                </div>
            </div>
        </div>
    );

    const IntegrationsSection = (
        <div className={styles.settings_section}>
            <div className={styles.settings_section_header}>Integrations</div>
            <div className={styles.settings_section_body}>
                <p>Connect your PMS and automatically sync the generated descriptions.</p>
                {pmsIntegrationControls}
            </div>
        </div>
    )

    const planTypeSection = (
        <>
            <div className={styles.settings_setting}>
                <div className={styles.settings_setting_title}>
                    Plan
                </div>
                <div className={styles.settings_setting_detail}>
                    {isAllPropertyPlan
                        ? 'All Properties Plan'
                        : 'Single Property Plan'}
                </div>
            </div>
            {!isAllPropertyPlan && (
                <div className={styles.settings_setting}>
                    <div className={styles.settings_setting_title}>
                        Property
                    </div>
                    <div className={styles.settings_setting_detail}>
                        <a href={`https://airbnb.com/rooms/${subscription.main_property}`}>
                            https://airbnb.com/rooms/{subscription.main_property}
                        </a>
                    </div>
                </div>
            )}
            {isAllPropertyPlan && (
                <div className={styles.settings_setting}>
                    <div className={styles.settings_setting_title}>
                        All properties ({subscription.num_properties})
                    </div>
                    <div className={styles.settings_setting_detail}>
                        <a
                            href={`https://airbnb.com/users/show/${subscription.airbnb_account_id}`}
                        >
                            <FontAwesomeIcon
                                size="sm"
                                icon={faArrowUpRight}
                                style={{ marginRight: "0.5rem" }}
                            />
                            https://airbnb.com/users/show/
                            {subscription.airbnb_account_id}
                        </a>
                    </div>
                </div>
            )}
        </>
    );

    const paymentIntervalSection = (
        <div className={styles.settings_setting}>
            <div className={styles.settings_setting_title}>
                Payment
            </div>
            <div className={styles.settings_setting_detail}>
                {isAnnualSubscription ? 'Annual' : 'Monthly'}
            </div>
        </div>
    );

    const planSection = (
        <div className={styles.settings_section}>
            <div className={styles.settings_section_header}>Plans</div>
            <div className={styles.settings_section_body}>
                {planTypeSection}
                {paymentIntervalSection}
                <div>
                    <button className={styles.settings_change_plan_button} onClick={openChangePlanModal}>
                        {isSubscriber ? 'Change Plan' : 'Renew Plan'}
                    </button>
                    {isSubscriber && (
                        <button className={styles.settings_button} onClick={openModal}>
                            Cancel Subscription
                        </button>
                    )}
                </div>
                <Dialog open={isModalOpen} onClose={closeModal} fullWidth maxWidth="xs">
                    <div className={styles.modal_header}>
                        <h2 className={styles.modal_header_title}>Cancel Subscription</h2>
                        <button className={styles.modal_header_button} onClick={closeModal}>
                            <FontAwesomeIcon
                                icon={faXmark}
                                size="sm"
                            />
                        </button>
                    </div>
                    <div className={styles.modal_body}>
                        <p>
                            Are you sure you want to cancel your subscription?
                            You will be downgraded at the end of your current billing cycle {nextBillingDate}.
                        </p>
                        <div className={styles.modal_actions}>
                            <button className={`${styles.button_secondary} ${styles.button_sm}`} onClick={closeModal}>Stay subscribed</button>
                            <button className={`${styles.button_primary} ${styles.button_sm}`} onClick={cancelSubscription}>Cancel Subscription</button>
                        </div>
                    </div>
                </Dialog>

                <Modal className={styles.settings_modal} isOpen={isChangePlanModalOpen} overlayClassName={styles.settings_modal_overlay}>
                    <ChangePlan onClose={closeChangePlanModal} isChangePlan={isSubscriber} />
                </Modal>
            </div>
        </div>
    )

    const contactSection = (
        <div className={styles.settings_section}>
            <div className={styles.settings_section_header}>Contact</div>
            <div className={styles.settings_section_body}>
                <a href='mailto:contact@gethostai.com'>contact@gethostai.com</a>
            </div>
        </div>
    )

    return (
        <div className={styles.user_settings}>
            <div className={styles.settings_title}>Settings</div>
            {emailSection}
            {IntegrationsSection}
            {planSection}
            {contactSection}
        </div>
    );
};

export default Settings;
