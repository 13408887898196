import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export const AlertDialog = ({ isAlertModalOpen, onClose, alertMessage }: {isAlertModalOpen: boolean, onClose: () => void, alertMessage: string }) => {
    return (
        <Dialog open={isAlertModalOpen} onClose={onClose}>
            <DialogTitle>Please note</DialogTitle>
            <DialogContent>
                <DialogContentText>{alertMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )

};